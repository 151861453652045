import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Home from '~/components/core/splash/Home';

import AdminAlerts from '~/util/adminAlerts';
import Notifications from '~/util/notifications';

import $store from '~/store';
import createRoute from './createRoute';

let initializedRouter = null;

function isRouteAllowed(route) {
    const isHome = route.path === '/';
    if (isHome) {
        return true;
    }
    const { id } = route.meta;
    return $store.getters['views/allowedViewIds'].includes(id);
}

function createRouter() {
    const routes = $store.getters['views/allTabsWithMenuItems'].filter(view => !view.menuComponent).map(createRoute);

    routes.push(
        {
            path: '/',
            component: Home
        },
        {
            path: '*',
            redirect: '/'
        }
    );

    const router = new Router({
        routes
    });

    router.beforeEach((to, from, next) => {
        $store.dispatch('views/setActiveRoute', to);
        next(isRouteAllowed(to) ? true : '/');
        window.scrollTo(0, 0);
    });

    router.afterEach((to, from) => {
        if (to.path !== from.path) {
            // only page changes, ignore query changes
            Notifications.hideAll();

            const viewId = to.params.menuitem || to.meta.id;
            const tabId = to.meta.id;
            AdminAlerts.checkAndShowAlerts(viewId, tabId);
        }
    });

    initializedRouter = router;
    return router;
}

export default {
    createRouter,
    getRouter: () => initializedRouter
};
