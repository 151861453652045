<template>
    <div class="feedbackPanel" ref="feedback">
        <b class="splash">Do you have feedback? Great!</b>

        <div class="listItem">
            For new feature wishes, improvements or general questions, please use our <i class="fa fa-slack" aria-hidden="true"></i> Slack
            channel
            <a rel="noopener noreferrer" href="https://cockpit-feedback.slack.com/messages/C52SE4G5V" target="_blank">in the browser</a> or
            <a rel="noopener noreferrer" href="slack://channel?team=T51F1D5G9&id=C52SE4G5V">in the client</a>.
        </div>

        <div class="listItem">
            For reporting bugs, malfunctions or data issues, please create a
            <a rel="noopener noreferrer" href="https://jira.bmw-carsharing.de/secure/CreateIssue!default.jspa" target="_blank"
                >support ticket</a
            >

            <template v-if="environment === 'PROD'">
                (<strong>Project</strong> 'Support ReachNow', <strong>Issue type</strong> 'Incident', <strong>Summary</strong> 'ODM Cockpit
                &amp; problem summary', <strong>Description</strong> 'copy URL').
            </template>
            <template v-else>
                (<strong>Project</strong> 'Defectmanagement', <strong>Issue type</strong> 'Defect', <strong>Summary</strong> 'ODM Cockpit
                &amp; problem summary', <strong>Description</strong> 'copy URL').
            </template>
        </div>
    </div>
</template>

<script>
import { EnvironmentService } from '@odm-operations-tooling/cockpit-commons';

export default {
    name: 'FeedbackPanel',

    data() {
        return {
            environment: EnvironmentService.getEnvironment()
        };
    },

    mounted() {
        this.$refs.feedback.querySelectorAll('a').forEach(a => {
            a.addEventListener('click', e => {
                e.stopPropagation();
            });
        });
    }
};
</script>

<style lang="scss">
.feedbackPanel {
    .splash {
        font-size: 110%;
    }

    .listItem {
        margin-top: 10px;

        &:before {
            font-family: 'FontAwesome';
            content: '\f0da';
            padding-right: 9px;
            display: inline-block;
        }
    }

    a {
        color: $color_ci_smalt;
        font-weight: bold;
    }
}
</style>
