// odm-user-iamnext-service

import AJAX from '~/server/ajax';
import { EnvironmentService, API } from '@odm-operations-tooling/cockpit-commons';

const IAMNEXT_BASE = API.iamnext;
const UserIamnextService = EnvironmentService.create({
    subdomain: IAMNEXT_BASE.namespace,
    defaultApiPath: {
        REST: '/api/',
        WS: ''
    },
    localdomain: 'localhost:7000'
});

const baseUrl = UserIamnextService.getRestUrl();

export default {
    async getPrincipalPermissions() {
        const response = await AJAX.get(baseUrl + IAMNEXT_BASE.userPermissions);
        const { permissionNames, allowedFleets } = response;
        return {
            permissions: permissionNames,
            allowedFleets
        };
    }
};
