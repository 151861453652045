<template>
    <splash-screen class="logoutScreen">
        <h2>{{ $t('core.splash.loggedOut') }}</h2>
        <cockpit-title medium></cockpit-title>

        <button class="ghostButton" @click="proceedToLogin">
            <i class="fa fa-sign-in" aria-hidden="true"></i> {{ $t('core.splash.logIn') }}
        </button>

        <div class="hint"><i class="fa fa-info-circle" aria-hidden="true"></i> {{ $t('core.splash.logInHint') }}</div>
    </splash-screen>
</template>

<script>
import CockpitTitle from '~/components/core/CockpitTitle';
import SplashScreen from '~/components/core/splash/SplashScreen';

export default {
    name: 'LogoutScreen',

    components: {
        CockpitTitle,
        SplashScreen
    },

    methods: {
        proceedToLogin() {
            window.location.pathname = 'loginCockpit';
        }
    }
};
</script>

<style lang="scss">
.logoutScreen {
    h2 {
        font-weight: normal;
        font-size: 30px;
        margin-bottom: 5px;
    }

    .ghostButton {
        background: transparent;
        border: 1px solid $color_ci_blue_shade1;
        border-radius: 4px;
        color: $color_ci_blue_shade1;
        font-weight: bold;
        font-size: 16px;
        padding: 4px 12px;
        display: block;
        margin: 30px auto 0;
        cursor: pointer;

        &:hover {
            background: $color_ci_blue_shade1;
            color: $color_white;
            text-decoration: none;
        }

        .fa {
            margin-right: 5px;
        }
    }

    .hint {
        font-size: 11px;
        text-align: center;
        margin-top: 17px;
    }
}

@media (max-width: $screen-lg) {
    .logoutScreen {
        margin-left: $csc_page_padding;
        margin-right: $csc_page_padding;

        h2 {
            font-size: 22px;
        }
    }
}
</style>
