import $store from '~/store';
import Notifications from '~/util/notifications';
import { MESSAGE_TYPE_KEYS } from '~/business/comprehensive/adminMessage/messageTypes';

function showMessage(msg) {
    const { message, type } = msg;
    switch (type) {
        case MESSAGE_TYPE_KEYS.SUCCESS:
            Notifications.showSuccess(message, false, true);
            break;
        case MESSAGE_TYPE_KEYS.WARNING:
            Notifications.showWarning(message, false, true);
            break;
        case MESSAGE_TYPE_KEYS.ERROR:
            Notifications.showError(message, false, true);
            break;
        case MESSAGE_TYPE_KEYS.NOTIFICATION:
            Notifications.showInfo(message, false, true);
            break;
    }
}

function showConditionalMessages(checkViewFunc) {
    $store.getters.alerts
        .filter(message => checkViewFunc(message.viewId) && message.condition())
        .forEach(message => {
            message.action();
            showMessage(message);
        });
}

export default {
    showGlobalAlerts() {
        showConditionalMessages(viewId => viewId === '*');
    },

    checkAndShowAlerts(id, tabId) {
        showConditionalMessages(viewId => viewId === id || viewId === tabId);
    }
};
