import { Util } from '@odm-operations-tooling/cockpit-commons';

const HIDE_TIMEOUT_DURATION = 4000;

export default {
    namespaced: true,

    state: {
        messages: []
    },

    getters: {
        messages: state => state.messages
    },

    actions: {
        showMessage({ commit, state }, { type, message, hideAfterTimeout = false, uniqueMessageText = false, info = undefined }) {
            if (uniqueMessageText) {
                // Make sure the given message text isn't displayed yet

                for (let i = 0, l = state.messages.length; i < l; ++i) {
                    if (state.messages[i].message === message && state.messages[i].type === type) {
                        return state.messages[i].id;
                    }
                }
            }

            const config = {
                id: Util.string.uuid(),
                type,
                message
            };

            if (info) {
                config.info = info;
            }

            if (hideAfterTimeout) {
                const timeout = typeof hideAfterTimeout === 'number' ? hideAfterTimeout : HIDE_TIMEOUT_DURATION;
                config.timeout = setTimeout(() => {
                    commit('DELETE_MESSAGE', config.id);
                }, timeout);
            }

            commit('ADD_MESSAGE', config);
            return config.id;
        },

        hideMessage({ commit }, id) {
            commit('DELETE_MESSAGE', id);
        },

        hideAllMessages({ commit }) {
            commit('DELETE_ALL_MESSAGES');
        }
    },

    mutations: {
        ADD_MESSAGE(state, message) {
            state.messages.push(message);
        },

        DELETE_MESSAGE(state, id) {
            let index = -1;
            for (let i = 0, l = state.messages.length; i < l; ++i) {
                if (state.messages[i].id === id) {
                    index = i;
                    break;
                }
            }
            if (index > -1) {
                clearTimeout(state.messages[index].timeout);
                state.messages.splice(index, 1);
            }
        },

        DELETE_ALL_MESSAGES(state) {
            state.messages = [];
        }
    }
};
