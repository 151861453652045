<template>
    <div>
        <iframe class="legacyCockpitFrame" ref="frame" allowTransparency="true"></iframe>

        <!-- For fleet tab only -->
        <vehicle-details v-if="isHybridFleetManagementPage" ref="vehicleDetails" @close="onCloseVehicleDetailsModal"> </vehicle-details>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IFrameBridge from '@odm-operations-tooling/iframe-bridge';
import { BRIDGE_EVENTS, Util, SESSION_STORAGE_KEYS } from '@odm-operations-tooling/cockpit-commons';
import * as Authentication from '~/util/authentication';

import VehicleDetails from '~/components/comprehensive/vehicleDetails/VehicleDetails';

import DeepLinks from '~/util/deepLinks';
import UserPreferences from '~/util/localStorage/userPreferences';

// Keep track of active instances to manage class at <body> level
// This is necessary because when switching component instances, Vue first creates a second instance before destroying the first one

const instanceActivationState = {
    isAnyActive: false,
    preventNextDestroy: false
};

const ALLOWED_FEATURES = ['geolocation'];

export default {
    name: 'ExternalApplicationFrame',

    components: {
        VehicleDetails
    },

    data() {
        return {
            queryParams: '',
            fleetTabDetailsPageActive: false,
            isModalShown: false,
            listenerIds: {
                events: [],
                providers: []
            },
            allowedFeatures: ALLOWED_FEATURES.join(' ')
        };
    },

    computed: {
        ...mapGetters('views', ['isHybridFleetManagementPage', 'activeIframe', 'activeId']),
        iframeSrc() {
            return this.activeIframe.url + this.queryParams;
        }
    },

    watch: {
        iframeSrc(src) {
            this.setIframeSrc(src);

            let queryParams = '';
            if (this.hasQueryParams()) {
                queryParams = this.transformQueryParamsToUrlString(DeepLinks.getQuery());
            }
            this.setQueryParams(queryParams);
        }
    },

    created() {
        if (instanceActivationState.isAnyActive) {
            instanceActivationState.preventNextDestroy = true;
        }
        instanceActivationState.isAnyActive = true;
        document.body.classList.add('legacyCockpitFrameActive');
    },

    mounted() {
        this.setIframeSrc(this.iframeSrc);
        if (this.hasQueryParams()) {
            this.setQueryParams(this.transformQueryParamsToUrlString(DeepLinks.getQuery()));
        }

        this.listenerIds.events.push(IFrameBridge.registerEventListener(BRIDGE_EVENTS.URL_PARAMS_UPDATED, this.onURLParamsUpdated)); // listens to deep link changes in the child
        this.listenerIds.events.push(IFrameBridge.registerEventListener(BRIDGE_EVENTS.URL_PATH_UPDATED, this.onURLPathUpdated));
        this.listenerIds.events.push(IFrameBridge.registerEventListener(BRIDGE_EVENTS.HEIGHT_CHANGED, this.onHeightChanged)); // listens to height changes in the child
        this.listenerIds.events.push(IFrameBridge.registerEventListener(BRIDGE_EVENTS.VEHICLE_DETAILS, this.onOpenVehicleDetailsModal)); // open vehicle details modal from old Fleet tab
        this.listenerIds.events.push(
            IFrameBridge.registerEventListener(BRIDGE_EVENTS.FLEET_CURRENT_FILTER, UserPreferences.setCurrentFleet)
        );

        this.listenerIds.providers.push(
            IFrameBridge.registerContentProvider(BRIDGE_EVENTS.ON_MODAL_STATUS_CHANGE, this.onModalStatusChanged)
        ); // listens to modal open/close events to position frame correctly (workaround)
        this.listenerIds.providers.push(
            IFrameBridge.registerContentProvider(BRIDGE_EVENTS.FLEET_DETAILS_PAGE_ACTIVE, this.onFleetTabDetailsPageToggle)
        ); // listens to the current fleet tab view; needs to be a content provider because child requires an answer!

        this.listenerIds.providers.push(
            IFrameBridge.registerContentProvider(SESSION_STORAGE_KEYS.TOKEN, () => Authentication.getRawToken())
        );
        this.listenerIds.providers.push(IFrameBridge.registerContentProvider(BRIDGE_EVENTS.GLOBAL_SETTINGS, this.provideGlobalSettings)); // returns global settings like distance unit

        this.windowResizeListener = Util.function.debounce(() => {
            IFrameBridge.triggerEventInIFrame(this.$refs.frame, BRIDGE_EVENTS.ON_PARENT_WINDOW_RESIZED);
        }, 250);

        window.addEventListener('resize', this.windowResizeListener);
    },

    destroyed() {
        if (!instanceActivationState.preventNextDestroy) {
            document.body.classList.remove('legacyCockpitFrameActive');
            instanceActivationState.isAnyActive = false;
        }
        instanceActivationState.preventNextDestroy = false;

        this.listenerIds.events.forEach(id => IFrameBridge.unregisterEventListener(id));
        this.listenerIds.providers.forEach(id => IFrameBridge.unregisterContentProvider(id));

        window.removeEventListener('resize', this.windowResizeListener);

        document.body.classList.remove('isModalShown');
    },

    methods: {
        setQueryParams(params) {
            this.queryParams = params;
        },

        setIframeSrc(src) {
            this.$refs.frame.setAttribute('allow', this.allowedFeatures);
            this.$refs.frame.src = src;
        },

        onURLParamsUpdated(params) {
            if (this.$route.meta.id === this.activeId) {
                // additional check to prevent iframebridge to overwrite vue route

                DeepLinks.updateQuery(this.paramsHaveAtLeastOneNonNullValue(params) ? params : {});
            }
        },

        onURLPathUpdated(pathAndParams) {
            const { path, params } = pathAndParams;
            DeepLinks.updatePath(path, this.paramsHaveAtLeastOneNonNullValue(params) ? params : {});
        },

        paramsHaveAtLeastOneNonNullValue(params) {
            return Object.values(params).filter(value => value !== null).length > 0;
        },

        onHeightChanged(height) {
            const minHeight = window.innerHeight - 129;
            let actualHeight = Math.max(height, minHeight);

            // Special treatment for fleet tab which always has to be 100% high and must not have scrollbars

            if (this.isHybridFleetManagementPage && !this.fleetTabDetailsPageActive) {
                actualHeight = minHeight;
            }

            this.$refs.frame.style.height = Math.ceil(actualHeight) + 'px';
        },

        onOpenVehicleDetailsModal(vehicles) {
            this.$refs.vehicleDetails.$emit('open', JSON.parse(vehicles), true);
        },

        onCloseVehicleDetailsModal() {
            IFrameBridge.triggerEventInIFrame(this.$refs.frame, BRIDGE_EVENTS.VEHICLE_DETAILS, 'close');
        },

        onModalStatusChanged(isModalShown) {
            this.isModalShown = isModalShown;
            document.body.classList[isModalShown ? 'add' : 'remove']('isModalShown');
            return UserPreferences.getNavLabelsShown();
        },

        onFleetTabDetailsPageToggle(isActive) {
            this.fleetTabDetailsPageActive = isActive;
        },

        hasQueryParams() {
            return Object.keys(DeepLinks.getQuery()).length > 0;
        },

        transformQueryParamsToUrlString(queryParams) {
            const separator = this.activeIframe.usesLegacySearchQueryDivider ? '@' : '?';
            return (
                separator +
                Object.keys(queryParams)
                    .map(key => `${key}=${queryParams[key]}`)
                    .join('&')
            );
        },

        provideGlobalSettings() {
            return UserPreferences.getGlobalSettings();
        }
    }
};
</script>

<style lang="scss">
.mobile {
    .legacyCockpitFrame {
        width: calc(100vw);
        height: calc(100vh - #{50px});
    }
}
.legacyCockpitFrame {
    width: calc(100vw - #{$csc_nav_default_width + $csc_page_padding + 17px});
    height: calc(100vh - #{$csc_header_height + $csc_footer_height + $csc_page_padding + 5px});
    border: 0;

    body.showNavBarLabels & {
        width: calc(100vw - #{$csc_nav_default_width + $csc_nav_labels_width + $csc_page_padding + 17px});
    }

    body.isModalShown & {
        height: 100vh !important;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        z-index: $z-index-legacy-modal;
    }
}

body.isModalShown {
    $padding-right: 16px;

    overflow-y: hidden;
    padding-right: $padding-right;

    & .appHeader {
        padding-right: 25px + $padding-right;
    }
}
</style>
