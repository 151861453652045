import { initialState, VERSION, DEFAULT_FLEET } from '~/util/localStorage/initialStorageState';

const KEY = 'CSCUserPreferences';

let currentState = initialState;

function saveDataInStorage() {
    localStorage.setItem(KEY, JSON.stringify(currentState));
}

function initialize() {
    const storageItem = localStorage.getItem(KEY);
    if (storageItem === null) {
        currentState = initialState;
        saveDataInStorage();
        return;
    }

    const storedData = JSON.parse(storageItem);
    if (storedData.version === VERSION) {
        currentState = storedData;
    } else {
        console.warn('User Preferences: Store version mismatch, discarding stored data.'); // eslint-disable-line
        currentState = initialState;
        saveDataInStorage();
    }
}

function getSelectedTableColumns() {
    return currentState.ridehailing.trips.selectedTableColumns;
}

function setSelectedTableColumns(selected) {
    currentState.ridehailing.trips.selectedTableColumns = selected;
    saveDataInStorage();
}

function getCurrentState() {
    return currentState;
}

function setCurrentState(state) {
    currentState = state;
    saveDataInStorage();
}

function getFleetManagementVehicles() {
    return currentState.fleetManagement.vehicles;
}

function getFleetManagementVehiclesSelectedTableColumns() {
    return currentState.fleetManagement.vehicles.selectedTableColumns;
}

function setFleetManagementVehiclesSelectedTableColumns(selected) {
    currentState.fleetManagement.vehicles.selectedTableColumns = selected;
    saveDataInStorage();
}

function getFleetManagementVehiclesSelectedTableFilters() {
    return currentState.fleetManagement.vehicles.selectedTableFilters;
}

function setFleetManagementVehiclesSelectedTableFilters(selected) {
    currentState.fleetManagement.vehicles.selectedTableFilters = {
        ...currentState.fleetManagement.vehicles.selectedTableFilters,
        ...selected
    };
    saveDataInStorage();
}

function clearFleetManagementSelectedTableFilters() {
    currentState.fleetManagement.vehicles.selectedTableColumns = initialState.fleetManagement.vehicles.selectedTableColumns;
    currentState.fleetManagement.vehicles.selectedTableFilters = initialState.fleetManagement.vehicles.selectedTableFilters;
    currentState.fleetManagement.ridehailing.trips.selectedTableColumns =
        initialState.fleetManagement.ridehailing.trips.selectedTableColumns;
    currentState.fleetManagement.ridehailing.trips.selectedTableFilters =
        initialState.fleetManagement.ridehailing.trips.selectedTableFilters;
    currentState.fleetManagement.ridehailing.vehicles.selectedTableColumns =
        initialState.fleetManagement.ridehailing.vehicles.selectedTableColumns;
    currentState.fleetManagement.ridehailing.vehicles.selectedTableFilters =
        initialState.fleetManagement.ridehailing.vehicles.selectedTableFilters;
    saveDataInStorage();
}

function getFleetManagementRidehailingTripsSelectedTableColumns() {
    return currentState.fleetManagement.ridehailing.trips.selectedTableColumns;
}

function setFleetManagementRidehailingTripsSelectedTableColumns(selected) {
    currentState.fleetManagement.ridehailing.trips.selectedTableColumns = selected;
    saveDataInStorage();
}

function getFleetManagementRidehailingTripsSelectedTableFilters() {
    return currentState.fleetManagement.ridehailing.trips.selectedTableFilters;
}

function setFleetManagementRidehailingTripsSelectedTableFilters(selected) {
    currentState.fleetManagement.ridehailing.trips.selectedTableFilters = {
        ...currentState.fleetManagement.ridehailing.trips.selectedTableFilters,
        ...selected
    };
    saveDataInStorage();
}

function getFleetManagementRidehailingVehiclesSelectedTableColumns() {
    return currentState.fleetManagement.ridehailing.vehicles.selectedTableColumns;
}

function setFleetManagementRidehailingVehiclesSelectedTableColumns(selected) {
    currentState.fleetManagement.ridehailing.vehicles.selectedTableColumns = selected;
    saveDataInStorage();
}

function getFleetManagementRidehailingVehiclesSelectedTableFilters() {
    return currentState.fleetManagement.ridehailing.vehicles.selectedTableFilters;
}

function setFleetManagementRidehailingVehiclesSelectedTableFilters(selected) {
    currentState.fleetManagement.ridehailing.vehicles.selectedTableFilters = {
        ...currentState.fleetManagement.ridehailing.vehicles.selectedTableFilters,
        ...selected
    };
    saveDataInStorage();
}

function getFleetManagementPersistFilterSelection() {
    return currentState.fleetManagement.persistFilterSelection;
}

function setFleetManagementPersistFilterSelection(persistFilterSelection) {
    currentState.fleetManagement.persistFilterSelection = persistFilterSelection;
    saveDataInStorage();
}

function getUtilizationKpiSelectedTableColumns() {
    return currentState.reporting.utilizationKpiTableColumns;
}

function setUtilizationKpiSelectedTableColumns(selected) {
    currentState.reporting.utilizationKpiTableColumns = selected;
    saveDataInStorage();
}

function getSelectedReportingFleets() {
    return currentState.reporting.fleetFilter;
}

function setSelectedReportingFleets(selected) {
    currentState.reporting.fleetFilter = selected;
    saveDataInStorage();
}

function getNavLabelsShown() {
    return currentState.areNavLabelsShown;
}

function setNavLabelsShown(isShown) {
    currentState.areNavLabelsShown = isShown;
    saveDataInStorage();
}

function getCollapsibleWidgetState(id) {
    return currentState.collapsibleWidgets[id] || false;
}

function setCollapsibleWidgetState(id, isCollapsed) {
    currentState.collapsibleWidgets[id] = isCollapsed;
    saveDataInStorage();
}

function getMonitoringDashboardState(id) {
    return currentState.monitoring.dashboards[id] || null;
}

function setMonitoringDashboardState(id, states) {
    currentState.monitoring.dashboards[id] = states;
    saveDataInStorage();
}

function getMonitoringSelectedFleets() {
    return currentState.monitoring.fleets;
}

function setMonitoringSelectedFleets(fleets) {
    currentState.monitoring.fleets = fleets;
    saveDataInStorage();
}

function getDefaultFleet() {
    return DEFAULT_FLEET;
}

function getCurrentFleet() {
    return currentState.currentFleet || getDefaultFleet();
}

function setCurrentFleet(fleet) {
    currentState.currentFleet = fleet;
    saveDataInStorage();
}

function getTasksCollectedVins() {
    return currentState.tasks.collectedVins;
}

function setTasksCollectedVins(collectedVins) {
    currentState.tasks.collectedVins = collectedVins;
    saveDataInStorage();
}

function getVehicleHealthRecordStatefulLanes() {
    return currentState.vehicleHealthRecord.lanes;
}

function setVehicleHealthRecordStatefulLanes(lanes) {
    currentState.vehicleHealthRecord.lanes = lanes;
    saveDataInStorage();
}

function getVehicleHealthRecordStatelessLanes() {
    return currentState.vehicleHealthRecord.statelessLanes;
}

function setVehicleHealthRecordStatelessLanes(lanes) {
    currentState.vehicleHealthRecord.statelessLanes = lanes;
    saveDataInStorage();
}

function getGlobalSettings() {
    return currentState.globalSettings;
}

function setPartialGlobalSettings(partialSettings) {
    currentState.globalSettings = { ...currentState.globalSettings, ...partialSettings };
    saveDataInStorage();
}

export default {
    initialize,
    getNavLabelsShown,
    setNavLabelsShown,
    getCollapsibleWidgetState,
    setCollapsibleWidgetState,
    getMonitoringDashboardState,
    setMonitoringDashboardState,
    getMonitoringSelectedFleets,
    setMonitoringSelectedFleets,
    getCurrentFleet,
    setCurrentFleet,
    getSelectedTableColumns,
    setSelectedTableColumns,
    getTasksCollectedVins,
    setTasksCollectedVins,
    getVehicleHealthRecordStatefulLanes,
    setVehicleHealthRecordStatefulLanes,
    getVehicleHealthRecordStatelessLanes,
    setVehicleHealthRecordStatelessLanes,
    getGlobalSettings,
    setPartialGlobalSettings,
    getDefaultFleet,
    getUtilizationKpiSelectedTableColumns,
    setUtilizationKpiSelectedTableColumns,
    getSelectedReportingFleets,
    setSelectedReportingFleets,
    getCurrentState,
    setCurrentState,
    getFleetManagementVehicles,
    getFleetManagementVehiclesSelectedTableColumns,
    // TODO: rename
    setFleetManagementVehiclesSelectedTableColumns,
    getFleetManagementVehiclesSelectedTableFilters,
    setFleetManagementVehiclesSelectedTableFilters,
    getFleetManagementPersistFilterSelection,
    setFleetManagementPersistFilterSelection,
    getFleetManagementRidehailingTripsSelectedTableColumns,
    setFleetManagementRidehailingTripsSelectedTableColumns,
    getFleetManagementRidehailingTripsSelectedTableFilters,
    setFleetManagementRidehailingTripsSelectedTableFilters,
    getFleetManagementRidehailingVehiclesSelectedTableColumns,
    setFleetManagementRidehailingVehiclesSelectedTableColumns,
    getFleetManagementRidehailingVehiclesSelectedTableFilters,
    setFleetManagementRidehailingVehiclesSelectedTableFilters,
    clearFleetManagementSelectedTableFilters
};
