import { KeycloakAdapter, SessionService } from '@odm-operations-tooling/cockpit-commons';
import { UserTokenFactory } from '@odm-operations-tooling/cockpit-commons';
import Environment from '~/util/environment';
import jwtDecode from 'jwt-decode';

const IDToken = process.env.VUE_APP_ID_TOKEN;

function parseNameInfoFromToken(rawToken) {
    let username, displayName;
    try {
        const { name, preferred_username } = jwtDecode(rawToken);
        username = preferred_username;
        displayName = name;
    } catch (e) {
        console.error(e); //eslint-disable-line
    }

    return {
        username,
        displayName
    };
}

async function initializeKeycloak() {
    let keycloakInstance = KeycloakAdapter.create('odm-cockpit');

    return keycloakInstance.init({
        promiseType: 'native',
        onLoad: 'login-required',
        checkLoginIframe: false
    });
}

export function getUserToken() {
    if (Environment.isLocal() && IDToken) {
        return UserTokenFactory.create(IDToken);
    }
    return KeycloakAdapter.getUserToken();
}

export function initialize() {
    return initializeKeycloak();
}

export function hasValidToken() {
    return getUserToken().isValid();
}

export function getRawToken() {
    return getUserToken().getRawToken();
}

export function getUserUUID() {
    return getUserToken().getUserUUID();
}

export function getUser() {
    return {
        ...parseNameInfoFromToken(getRawToken()),
        uuid: getUserUUID()
    };
}

export async function logout() {
    await KeycloakAdapter.logout();
    SessionService.clear();
}
