import { Util, VIEWS } from '@odm-operations-tooling/cockpit-commons';

import { COCKPIT_URL, LOCAL_URL } from '~/assets/config/legacyCockpitURLs';
import iFrameMapping from '~/assets/config/iFrameMapping';

import BuildVariables from '~/util/buildVariables';
import i18nPromise from '~/i18n';
import { isAllowed } from '~/util/authorization';

function getIframeUrlForPageId(id) {
    if (iFrameMapping.hasOwnProperty(id)) {
        iFrameMapping[id] = iFrameMapping[id].replace('@:filters', '');
        if (!BuildVariables.isProductionEnv()) {
            return iFrameMapping[id].replace(COCKPIT_URL, LOCAL_URL);
        }
        return iFrameMapping[id];
    }
    return null;
}

function generateMenuStructure(menus) {
    return menus
        .filter(menuItem => {
            if (menuItem.noview) {
                return false;
            }
            if (!Array.isArray(menuItem.children)) {
                return (
                    isAllowed(menuItem.id) ||
                    (menuItem.additionalAllowedPermissions &&
                        menuItem.additionalAllowedPermissions.some(permission => isAllowed(permission)))
                );
            }
            return menuItem.children.filter(({ id }) => isAllowed(id)).length !== 0;
        })
        .map(menuItem => {
            if (Array.isArray(menuItem.children)) {
                // Check permissions right here in order to correctly disable empty submenus
                menuItem.children = menuItem.children.filter(view => isAllowed(view.id) && !view.noview);

                menuItem.children.forEach(item => {
                    item.iframeUrl = getIframeUrlForPageId(item.id);
                });
            } else {
                menuItem.iframeUrl = getIframeUrlForPageId(menuItem.id);
            }
            return menuItem;
        });
}

function toTabsWithMenuItems(views) {
    return views.tabs.map(tab => {
        if (views.menus[tab.id]) {
            tab.menu = generateMenuStructure(views.menus[tab.id]);
        } else {
            tab.menu = views.menus[tab.id];
        }
        tab.iframeUrl = getIframeUrlForPageId(tab.id);
        // DeeplinkService in Cockpit uses @ sign as query divider
        // need to set a flag here to use it in ExternalApplicationFrame
        tab.usesLegacySearchQueryDivider = tab.href.indexOf(':filters') !== -1;
        tab.href = tab.href.replace(/\?(\@\:filters)?/, '');
        return tab;
    });
}

function getMenuItemByIdMapping(tabsWithMenuItems) {
    const result = {};

    for (const tab of tabsWithMenuItems) {
        result[tab.id] = { item: tab, iframeUrl: tab.iframeUrl };
        if (tab.menu) {
            for (const item of tab.menu) {
                if (item.children) {
                    for (const child of item.children) {
                        result[child.id] = { item: child, parentTab: tab, parent: item, tab, iframeUrl: child.iframeUrl };
                    }
                } else {
                    result[item.id] = { item, tab, parentTab: tab, iframeUrl: item.iframeUrl };
                }
            }
        }
    }

    return result;
}

function toMenuItem(id, name, tabId, tabName) {
    const isTabMenu = !(tabId && tabName);
    const item = { id, name, isTabMenu };

    if (!isTabMenu) {
        item.parentId = tabId;
        item.parentName = tabName;
    }

    return item;
}

let i18n;
i18nPromise.then(response => {
    i18n = response;
});

function translateItem(item) {
    try {
        if (item.isTabMenu) {
            // only main menu items are translatable
            item.name = i18n.t('core.navigation.' + item.name);
        } else if (item.parentName) {
            item.parentName = i18n.t('core.navigation.' + item.parentName);
        }
        return item;
    } catch (e) {
        console.error(e); // eslint-disable-line
        return item;
    }
}

// Assemble a list of all available views that can be permitted/denied for users/groups
function getViewStructure() {
    const views = [];

    // Get all tabs
    VIEWS.tabs.forEach(tab => {
        views.push(toMenuItem(tab.id, tab.title));

        // For each tab, find all menus
        if (VIEWS.menus[tab.id]) {
            VIEWS.menus[tab.id].forEach(menuItem => {
                if (menuItem.children === false) {
                    // Menu item doesn't have subitems
                    views.push(toMenuItem(menuItem.id, menuItem.name, tab.id, tab.title));
                } else {
                    // Menu item does have subitems, add those instead of parent item
                    menuItem.children.forEach(subItem => views.push(toMenuItem(subItem.id, subItem.name, tab.id, tab.title)));
                }
            });
        }
    });

    // Add additional views
    VIEWS.additional.forEach(additional => views.push({ ...additional, isTabMenu: false }));

    return views.map(translateItem);
}

function iterateMenuTree(targetList, tab, array) {
    array.forEach(menuItem => {
        if (Array.isArray(menuItem.children)) {
            iterateMenuTree(targetList, tab, menuItem.children);
        } else {
            targetList.push(menuItem.id);
        }
    });
}

function getViewIds(allowedTabsWithMenuItems) {
    const viewIds = [];
    allowedTabsWithMenuItems.forEach(tab => {
        const { id, menu } = tab;
        viewIds.push(id);
        if (menu && menu.length > 0) {
            iterateMenuTree(viewIds, tab, menu);
        }
    });
    return viewIds;
}

export default {
    namespaced: true,

    state: {
        allowedTabsWithMenuItems: [],
        allTabsWithMenuItems: [],
        menuItemIdMapping: {},
        activeRoute: null
    },

    getters: {
        allowedTabsWithMenuItems: state => state.allowedTabsWithMenuItems,
        allowedViewIds: state => getViewIds([...state.allowedTabsWithMenuItems]),
        allTabsWithMenuItems: state => state.allTabsWithMenuItems,

        activeId(state) {
            if (!state.activeRoute) {
                return '';
            }
            if (state.activeRoute.params.hasOwnProperty('menuitem')) {
                return state.activeRoute.params.menuitem;
            }
            return state.activeRoute.meta.id;
        },

        isHybridFleetManagementPage(state, getters) {
            return ['csc_operations_fleet', 'csc_ridehailing'].includes(getters.activeId);
        },

        activeTabId(state) {
            if (!state.activeRoute || !state.activeRoute.meta || !state.activeRoute.meta.id) {
                return '';
            }
            return state.activeRoute.meta.id;
        },

        activeView(state, getters) {
            return state.menuItemIdMapping[getters.activeId];
        },

        activeIframe(state) {
            let activeId = '';
            if (!state.activeRoute) {
                return '';
            }

            // Subpage: Get ID from menuitem
            const hasMenuItem = state.activeRoute.params.hasOwnProperty('menuitem');
            if (hasMenuItem || state.activeRoute.matched.length > 1) {
                activeId = hasMenuItem ? state.activeRoute.params.menuitem : state.activeRoute.meta.id;

                const subpage = state.menuItemIdMapping[activeId];
                if (!subpage || !subpage.hasOwnProperty('item') || !subpage.item.hasOwnProperty('iframeUrl')) {
                    throw `No iframe available for ${activeId}.`;
                }

                let url = subpage.item.iframeUrl;

                // special handling for wildcard urls
                if (state.activeRoute.params && state.activeRoute.params.hasOwnProperty(0) && state.activeRoute.params[0]) {
                    url += '/' + state.activeRoute.params[0];
                }

                return {
                    url: url,
                    usesLegacySearchQueryDivider: subpage.tab.usesLegacySearchQueryDivider
                };
            }

            // Main page: Get ID from meta
            const tabsItems = state.allowedTabsWithMenuItems;
            activeId = state.activeRoute.meta.id;

            const foundPage = tabsItems.find(item => item.id === activeId);
            if (!foundPage || !foundPage.hasOwnProperty('iframeUrl')) {
                throw `No iframe available for ${activeId}.`;
            }
            return {
                url: foundPage.iframeUrl,
                usesLegacySearchQueryDivider: foundPage.usesLegacySearchQueryDivider
            };
        },

        views: () => {
            return getViewStructure();
        }
    },

    actions: {
        initialize({ commit }) {
            const views = Util.object.clone(VIEWS);
            const allTabsWithMenuItems = toTabsWithMenuItems(views);
            const allowedTabsWithMenuItems = toTabsWithMenuItems({
                ...views,
                tabs: views.tabs.filter(({ id }) => isAllowed(id))
            });

            commit('SET_ALL_TABS_WITH_MENU_ITEMS', allTabsWithMenuItems);
            commit('SET_ALLOWED_TABS_WITH_MENU_ITEMS', allowedTabsWithMenuItems);
            commit('SET_MENU_ITEM_ID_MAPPING', getMenuItemByIdMapping(allowedTabsWithMenuItems));
        },

        setActiveRoute({ commit }, activeRoute) {
            commit('SET_ACTIVE_ROUTE', activeRoute);
        }
    },

    mutations: {
        SET_ALL_TABS_WITH_MENU_ITEMS(state, data) {
            state.allTabsWithMenuItems = data;
        },
        SET_ALLOWED_TABS_WITH_MENU_ITEMS(state, data) {
            state.allowedTabsWithMenuItems = data;
        },

        SET_MENU_ITEM_ID_MAPPING(state, data) {
            state.menuItemIdMapping = data;
        },

        SET_ACTIVE_ROUTE(state, data) {
            state.activeRoute = data;
        }
    }
};
