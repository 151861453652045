<template>
    <div class="appLoadingBar" ref="loadingBar">
        <div class="progressBar">
            <div class="indeterminate"></div>
        </div>
    </div>
</template>

<script>
import IFrameBridge from '@odm-operations-tooling/iframe-bridge';
import { BRIDGE_EVENTS } from '@odm-operations-tooling/cockpit-commons';

import Events from '~/assets/events';

export default {
    name: 'AppLoadingBar',

    mounted() {
        this.eventListenerId = IFrameBridge.registerEventListener(BRIDGE_EVENTS.TOGGLE_LOADING_BAR, isShown => {
            this.toggleBar(isShown);
        });

        this.$eventBus.$on(Events.requestInProgress, this.toggleBar);
    },

    destroyed() {
        IFrameBridge.unregisterEventListener(this.eventListenerId);
        this.$eventBus.$off(Events.requestInProgress, this.toggleBar);
    },

    methods: {
        toggleBar(isShown) {
            this.$refs.loadingBar.style.display = isShown ? 'block' : 'none';
        }
    }
};
</script>

<style lang="scss">
.appLoadingBar {
    display: none;
    position: fixed;
    z-index: $z-index-medium-high;
    top: 0;
    left: 0;
    width: 100%;

    .progressBar {
        position: relative;
        height: 5px;
        display: block;
        width: 100%;
        background-color: transparent;
        background-clip: padding-box;
        overflow: hidden;
        margin-bottom: 0 !important;
        z-index: $z-index-always-on-top;

        .determinate {
            position: absolute;
            top: 0;
            bottom: 0;
            background-color: $color_ci_blue_shade1;
            transition: width 0.3s linear;
        }

        .indeterminate {
            background-color: $color_ci_blue_shade1;

            &:before {
                content: '';
                position: absolute;
                background-color: inherit;
                top: 0;
                left: 0;
                bottom: 0;
                will-change: left, right;
                animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
            }

            &:after {
                content: '';
                position: absolute;
                background-color: inherit;
                top: 0;
                left: 0;
                bottom: 0;
                will-change: left, right;
                animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
                animation-delay: 1.15s;
            }
        }
    }

    @keyframes indeterminate {
        0% {
            left: -35%;
            right: 100%;
        }
        60% {
            left: 100%;
            right: -90%;
        }
        100% {
            left: 100%;
            right: -90%;
        }
    }
    @keyframes indeterminate-short {
        0% {
            left: -200%;
            right: 100%;
        }
        60% {
            left: 107%;
            right: -8%;
        }
        100% {
            left: 107%;
            right: -8%;
        }
    }
}
</style>
