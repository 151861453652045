const SUPPORTED_LANGUAGES = ['en', 'zh'];

function getUserLanguage() {
    const nav = window.navigator;
    const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];

    // Check HTML5 languages array

    if (Array.isArray(nav.languages)) {
        for (const language of nav.languages) {
            if (language && language.length) {
                return language.substr(0, 2).toLowerCase();
            }
        }
    }

    // Support for IE

    for (const key of browserLanguagePropertyKeys) {
        const language = nav[key];
        if (language && language.length) {
            return language.substr(0, 2).toLowerCase();
        }
    }

    return 'en';
}

export default {
    getSupportedUserLanguage() {
        const language = getUserLanguage();
        if (SUPPORTED_LANGUAGES.includes(language)) {
            return language;
        }
        return 'en';
    }
};
