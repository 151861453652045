import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

import LanguageDetection from '~/util/languageDetection';
const userLanguage = LanguageDetection.getSupportedUserLanguage();

import enStrings from '~/i18n/strings/en.json';
import zhStrings from '~/i18n/strings/zh.json';

let stringsImportPromise = Promise.resolve();
if (userLanguage !== 'en') {
    stringsImportPromise = import(/* webpackChunkName: "i18n" */ `@/i18n/strings/${userLanguage}.json`);
}

export default stringsImportPromise.then(localeStrings => {
    return new VueI18n({
        locale: userLanguage,
        fallbackLocale: 'en',
        silentTranslationWarn: true,
        messages: localeStrings ? { ...enStrings, ...zhStrings, ...localeStrings } : { ...enStrings }
    });
});
