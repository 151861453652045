import { Util } from '@odm-operations-tooling/cockpit-commons';

export const SUBPAGE_PARAM = ':menuitem';
const QUERY_PARAMS = [':filters'];
const URL_PARAM_DIVIDER = '@';

function removeParams(href) {
    return Util.string.remove([SUBPAGE_PARAM, URL_PARAM_DIVIDER, '?', ...QUERY_PARAMS], href);
}

export default {
    getSubpageHref(tab, item) {
        const hrefWithId = Util.string.replace(SUBPAGE_PARAM, item.id, tab.href);
        return removeParams(hrefWithId);
    },

    // Get the given tab's href without the optional subpage parameter; structure of "tab" parameter see VIEWS

    getTabHref(tab) {
        return removeParams(tab.href);
    }
};
