import Vue from 'vue';
import UserPreferences from '~/util/localStorage/userPreferences';
// updates of localStorage are triggered in the vuex store subscription of the localStorageStorePlugin
// vuex plugins can only be located on root level
export default {
    state: {
        persistentData: {},
        subscriptions: {}
    },
    getters: {
        persistentData: state => state.persistentData
    },
    actions: {
        initialisePersistentData({ commit }) {
            UserPreferences.initialize();
            commit('SET_PERSISTENT_DATA', UserPreferences.getCurrentState());
        },
        addPersistentDataSubscription({ commit }, payload) {
            commit('ADD_PERSISTENT_DATA_SUBSCRIPTION', payload);
        },
        removePersistentDataSubscription({ commit }, mutationType) {
            commit('REMOVE_PERSISTENT_DATA_SUBSCRIPTION', mutationType);
        }
    },
    mutations: {
        SET_PERSISTENT_DATA(state, data) {
            state.persistentData = data;
        },
        ADD_PERSISTENT_DATA_SUBSCRIPTION(state, { mutationType, getState }) {
            Vue.set(state.subscriptions, mutationType, getState);
        },
        REMOVE_PERSISTENT_DATA_SUBSCRIPTION(state, mutationType) {
            delete state.subscriptions[mutationType];
        }
    }
};
