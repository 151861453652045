export let moment;
export let d3;

export async function importMoment() {
    if (!moment) {
        const momentModule = await import(/* webpackChunkName: "moment" */ 'moment');
        // see https://medium.com/webpack/webpack-4-import-and-commonjs-d619d626b655
        moment = momentModule.default;
        const momentTimezone = await import(/* webpackChunkName: "moment" */ 'moment-timezone');
        moment.tz = momentTimezone.default.tz;
    }
}

export async function importD3() {
    if (!d3) {
        d3 = await import(/* webpackChunkName: "d3" */ 'd3');
    }
}

// Just for testing!

export function __setLibraries({ moment: m, d3: d3Lib }) {
    moment = m;
    d3 = d3Lib;
}
