<template>
    <div :class="{ appNavigation: true, condenseMenuItems }">
        <div class="labelsBar"></div>

        <navigation-hamburger ref="hamburger"></navigation-hamburger>

        <div
            v-for="(page, index) in pages"
            :key="page.id"
            :id="page.id"
            :class="{ navBarItem: true, current: page.id === activeTabId, menuActive: isMenuActive(page) }"
            tabindex="-1"
            @click="onPageItemClicked($event, page)"
        >
            <i :class="['fa', 'fa-' + page.icon]"></i>

            <div class="navBarLabel">{{ $t('core.navigation.' + page.title) }}</div>

            <div
                :class="{ navBarMenu: true, menuUpwards: index > MENU_UPWARDS_INDEX }"
                v-if="(page.menu && page.menu.length > 0) || page.menuComponent"
            >
                <div class="navBarMenuLabel">
                    <span>{{ $t('core.navigation.' + page.title) }}</span>
                </div>

                <navigation-menu-content v-if="!page.menuComponent" :page="page" @navigate="navigateToPage"> </navigation-menu-content>

                <div v-else class="menuComponent">
                    <component :is="page.menuComponent"></component>
                </div>

                <div class="navBarMenuLabel bottom" v-if="index > MENU_UPWARDS_INDEX"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Util, LayoutService } from '@odm-operations-tooling/cockpit-commons';

import FeedbackPanel from '~/components/core/navigation/FeedbackPanel'; // dynamically included via page.menuComponent
import NavigationHamburger from '~/components/core/navigation/NavigationHamburger';
import NavigationMenuContent from '~/components/core/navigation/NavigationMenuContent';
import onWindowResize from '~/components/util/mixins/onWindowResize';

import LinkUtils from '~/util/linkUtils';

const MENU_ITEM_HEIGHT = 44;
const MENU_OFFSET_TOP = 80;
const MENU_UPWARDS_INDEX = 11;

const tabsWithEnforcedTwoColumns = ['csc_people', 'csc_p2p_usage'];

export default {
    name: 'AppNavigation',

    components: {
        FeedbackPanel,
        NavigationHamburger,
        NavigationMenuContent
    },

    mixins: [onWindowResize],

    data() {
        return {
            activeMenuTabId: '',
            condenseMenuItems: false,
            MENU_UPWARDS_INDEX
        };
    },

    computed: {
        ...mapGetters('views', ['allowedTabsWithMenuItems', 'activeId', 'activeTabId']),

        pages() {
            const pages = Util.object.clone(this.allowedTabsWithMenuItems);
            pages.forEach(tab => {
                if (tab.menu && tab.menu.length) {
                    const numColumns = tab.menu.length >= 4 || tabsWithEnforcedTwoColumns.includes(tab.id) ? 2 : 1;
                    tab.menu = LayoutService.columnLayoutByColumns(tab.menu, numColumns);
                }
            });
            return pages;
        }
    },

    methods: {
        onWindowResize() {
            const { height } = this.getWindowSize();
            this.condenseMenuItems = this.pages.length * MENU_ITEM_HEIGHT + MENU_OFFSET_TOP > height; // ensure all menu items fit on the page
        },

        onPageItemClicked($event, page) {
            $event.preventDefault();

            // Only react to clicks directly on the item
            if (['fa', 'navBarItem', 'navBarLabel'].filter(c => $event.target.classList.contains(c)).length === 0) {
                return;
            }

            if ((!page.menu || page.menu.length === 0) && !page.menuComponent) {
                this.toggleMenu(null);
                this.navigateToPage(page);
                this.activeMenuTabId = page.id;
            } else if (this.activeMenuTabId === page.id) {
                // Deactivate when menu is already open
                this.toggleMenu(null);
            } else {
                this.toggleMenu(page.id);
            }
        },

        toggleMenu(id) {
            this.activeMenuTabId = id;
            window.removeEventListener('click', this.clickBlurHandler);
            window.removeEventListener('blur', this.blurHandler);

            if (id) {
                window.addEventListener('click', this.clickBlurHandler);
                window.addEventListener('blur', this.blurHandler);
            }
        },

        clickBlurHandler($event) {
            const path = $event.path || ($event.composedPath && $event.composedPath());
            if (path) {
                const navItemIndex = path.findIndex(element => {
                    return element.classList ? element.classList.contains('navBarItem') : false;
                });
                if (navItemIndex === -1) {
                    this.toggleMenu();
                }
            }
        },

        blurHandler() {
            // Also detect clicks in iframe to make the menu disappear
            if (document.activeElement.tagName.toLowerCase() === 'iframe') {
                this.toggleMenu();
            }
        },

        navigateToPage(page, item) {
            // Do nothing when selected page is already active
            const newId = item ? item.id : page.id;
            if (this.activeId === newId) {
                this.toggleMenu(null);
                return;
            }

            const route = item ? LinkUtils.getSubpageHref(page, item) : LinkUtils.getTabHref(page);
            setTimeout(() => {
                // make it a little smoother
                this.$router.push(route.substring(1));
            }, 100);

            this.toggleMenu(null);
        },

        isMenuActive(page) {
            return page.id === this.activeMenuTabId && ((page.menu && page.menu.length > 0) || page.menuComponent);
        }
    }
};
</script>

<style lang="scss">
.appNavigation {
    background: $color_ci_blue_shade1;
    width: $csc_nav_default_width + 1px;
    height: 100vh;
    position: fixed;
    z-index: $z-index-app-header + 1;
    top: 0;
    left: 0;
    user-select: none;

    @mixin fade_out($duration) {
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s ease-in-out $duration, opacity $duration ease-in-out;
        will-change: visibility, opacity;
    }
    @mixin fade_in() {
        pointer-events: all;
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
    }

    @mixin hover_state() {
        background: $color_ci_blue_shade3 !important;
        color: $color_white;
    }

    body.showNavBarLabels & {
        .labelsBar,
        .navBarLabel {
            @include fade_in();
        }
        .navBarMenu {
            margin-left: 42px + $csc_nav_labels_width;
        }
        .navBarMenuLabel span {
            visibility: hidden;
        }
    }

    .labelsBar {
        @include fade_out(0.4s);
        position: absolute;
        width: $csc_nav_labels_width;
        height: 100%;
        background: $color_gray_whisper;
        left: $csc_nav_default_width + 1px;
    }

    .navBarItem {
        font-size: 22px;
        color: $color_white;
        text-align: center;
        padding: 6px 17px 0;
        height: 44px;
        cursor: pointer;
        position: relative;

        &:hover,
        &:hover .navBarLabel {
            @include hover_state();
        }
        &.current,
        &.current .navBarLabel {
            background: $color_ci_blue_shade2;
            color: $color_white;
        }
        &:focus {
            outline: none;
        }
    }

    .navBarLabel,
    .navBarMenu {
        margin: -35px 0 0 42px;
    }

    .navBarLabel,
    .navBarMenuLabel {
        font-size: 17px;
        font-weight: bold;
        padding: 11px 12px 10px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.bottom {
            height: 42px;
        }
    }

    .navBarLabel {
        @include fade_out(0.4s);
        position: absolute;
        z-index: $z-index-raised-2;
        color: $color_ci_blue_shade1;
        width: $csc_nav_labels_width;
        letter-spacing: -1px;
    }

    .navBarMenuLabel {
        @include hover_state();
    }

    .navBarMenu {
        @include fade_out(0.25s);
        position: absolute;
        z-index: $z-index-raised-1;
        text-align: left;
        font-size: 15px;
        cursor: default;
        background: $color_gray_white_smoke;
        border-left: 0;
        color: $color_black;
        font-weight: bold;
        min-width: 350px;
        box-shadow: 2px 2px 2px transparentBlack(0.1);

        &.menuUpwards {
            bottom: 0;
        }
    }

    .menuActive {
        &.navBarItem,
        .navBarLabel {
            @include hover_state();
        }
        .navBarMenu {
            @include fade_in();
        }
    }

    .menuComponent {
        width: 680px;
        padding: 15px;
        font-weight: normal;
    }

    // Condense menu items when screen height is small so all items are shown including their menus
    &.condenseMenuItems {
        .navBarItem {
            font-size: 18px;
            padding-top: 5px;
            height: 32px;
        }

        .navBarMenuLabel.bottom {
            height: 32px;
        }

        .navBarLabel,
        .navBarMenuLabel {
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .navBarLabel,
        .navBarMenu {
            margin-top: -30px;
        }
    }
}
</style>
