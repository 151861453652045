// Listens to the window resize event and provides the current window size

import { Util } from '@odm-operations-tooling/cockpit-commons';

export default {
    data() {
        return {
            resizeListener: null
        };
    },

    created() {
        this.resizeListener = Util.function
            .debounce(function() {
                this.onWindowResize();
            }, 400)
            .bind(this);
    },

    mounted() {
        window.addEventListener('resize', this.resizeListener);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.resizeListener);
    },

    methods: {
        onWindowResize() {}, // to be overwritten by components

        getWindowSize() {
            const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            return { width, height };
        }
    }
};
