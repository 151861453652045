import PermissionRealtimeService from '~/business/comprehensive/permissionRealtimeService';
import UserIamNextApi from '~/server/userIamnext';
import * as Authentication from '~/util/authentication';
import $store from '~/store';
import Notifications from '~/util/notifications';

export function shouldReloadFleets(allowedFleetsIds) {
    const currentFleetIds = $store.getters['fleets/fleets'].map(({ fleetId }) => fleetId);
    const numberOfCommonFleets = currentFleetIds.filter(id => allowedFleetsIds.includes(id)).length;
    return numberOfCommonFleets !== currentFleetIds.length || numberOfCommonFleets !== allowedFleetsIds.length;
}

async function onPermissionUpdate({ permissionNames, allowedFleets }) {
    $store.dispatch('permissions/updateFleetsAndPermissions', {
        permissions: permissionNames,
        allowedFleets
    });

    if (shouldReloadFleets(allowedFleets)) {
        $store.dispatch('fleets/loadFleets');
    }

    $store.dispatch('views/initialize');
    Notifications.showInfo(`User permissions updated`, true);
}

export default {
    namespaced: true,

    state: {
        permissions: [],
        fleets: []
    },

    getters: {
        permissions: state => state.permissions,
        fleets: state => state.fleets
    },

    actions: {
        async loadPermissions({ commit }) {
            let response;
            try {
                response = await UserIamNextApi.getPrincipalPermissions();
            } catch (error) {
                if (error && error.info && error.info.response && error.info.response.status >= 400 && error.info.response.status < 500) {
                    await Authentication.logout();
                    throw 'Login error';
                } else {
                    throw error;
                }
            }

            commit('SET_PERMISSIONS', response.permissions);
            commit('SET_FLEETS', response.allowedFleets);
        },
        initializeLivePermissions() {
            PermissionRealtimeService.createAndOpen(onPermissionUpdate);
        },
        updateFleetsAndPermissions({ commit }, { permissions, allowedFleets }) {
            commit('SET_PERMISSIONS', permissions);
            commit('SET_FLEETS', allowedFleets);
        }
    },

    mutations: {
        SET_PERMISSIONS(state, permissions) {
            state.permissions = permissions;
        },

        SET_FLEETS(state, fleets) {
            state.fleets = fleets;
        }
    }
};
