<template>
    <div class="searchField">
        <autocomplete-field :placeholder="$t('core.header.search.searchText')" :options="targets" @selected="onItemSelected">
        </autocomplete-field>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Util, KPI_EXPLANATIONS } from '@odm-operations-tooling/cockpit-commons';

import AutocompleteField from '~/components/util/AutocompleteField';

import * as Authorization from '~/util/authorization';
import ExternalTools from '~/util/externalTools';
import LinkUtils from '~/util/linkUtils';

class SearchTarget {
    constructor(title, href, description = '', isExternalTool = false) {
        this._title = title;
        this._href = href;
        this._description = Util.string.stripTags(description).trim(); // remove all tags for performing the search (in order not to break the output)
        this._descriptionHtml = Util.string
            .stripTags(description, '<i><b><span><br>')
            .replace('<br><br>', '<br>')
            .trim(); // keep a few tags for displaying the description
        this._isExternalTool = isExternalTool;
    }

    getTitle() {
        return this._title;
    }
    getHref() {
        return this._href;
    }
    getDescription() {
        return this._descriptionHtml;
    }
    isExternalTool() {
        return this._isExternalTool;
    }

    doesMatch(term, regExp) {
        return regExp.test(this._title) || regExp.test(this._description);
    }
}

function getIconDescription(item, tags = []) {
    const { title, icon } = item;
    let description = `<i class="fa fa-${icon}" aria-hidden="true"></i> ${title}`;

    if (tags.length > 0) {
        description += '<br>' + tags.map(tag => `<span class="label">${tag}</span>`).join(' ');
    }

    return description;
}

/**
 * Goes through the array of submenu items and iteratively calls itself
 * @param targetList The list of results
 * @param tab The corresponding tab including its href, e.g. #/operations/parking
 * @param array The array of submenu items to go through
 */
function iterateMenuTree(targetList, tab, array) {
    array.forEach(menuItem => {
        if (Array.isArray(menuItem.children)) {
            iterateMenuTree(targetList, tab, menuItem.children);
        } else {
            targetList.push(
                new SearchTarget(
                    menuItem.name,
                    LinkUtils.getSubpageHref(tab, menuItem),
                    KPI_EXPLANATIONS[menuItem.id] || getIconDescription(tab)
                )
            );
        }
    });
}

function getTargets(allowedTabsWithMenuItems) {
    const targetList = [];

    allowedTabsWithMenuItems.forEach(tab => {
        const { title, menu } = tab;
        if (menu && menu.length > 0) {
            iterateMenuTree(targetList, tab, menu);
        } else {
            // Tab that has no children and should be directly accessible
            targetList.push(new SearchTarget(title, LinkUtils.getTabHref(tab), getIconDescription(tab)));
        }
    });

    if (Authorization.isAllowed('csc_external_tools')) {
        ExternalTools.getTools().forEach(tool => {
            const description = getIconDescription({ title: tool.link, icon: 'wrench' }, tool.keywords);
            targetList.push(new SearchTarget(tool.name, tool.link, description, true));
        });
    }

    Util.array.sortByKey(targetList, '_title');
    return targetList;
}

export default {
    name: 'SearchField',

    components: {
        AutocompleteField
    },

    computed: {
        ...mapGetters('views', ['allowedTabsWithMenuItems']),

        targets() {
            return getTargets(this.allowedTabsWithMenuItems);
        }
    },

    methods: {
        onItemSelected(item) {
            if (item.isExternalTool()) {
                window.open(item.getHref(), '_blank').focus();
            } else {
                this.$router.push({ path: item.getHref().replace('#', '') });
            }
        }
    }
};
</script>
