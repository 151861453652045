import { DEFAULT_MESSAGE_TYPE_KEY } from '~/business/comprehensive/adminMessage/messageTypes';
import { GLOBAL_VIEW_ID } from '~/business/comprehensive/adminMessage/globalViewId';

const DEFAULT_ADMIN_MESSAGE = {
    viewId: GLOBAL_VIEW_ID,
    type: DEFAULT_MESSAGE_TYPE_KEY,
    message: '',
    active: true,
    created: '',
    condition: () => true,
    action: () => {}
};

function createAdminMessage(message = {}) {
    return {
        ...DEFAULT_ADMIN_MESSAGE,
        ...{ created: new Date() },
        ...message
    };
}

function isValidAdminMessage(message) {
    return message.message.length > 0;
}

export { createAdminMessage, isValidAdminMessage };
