<template>
    <div id="app" :class="{ mobile: isMobile }">
        <template v-if="isLoggedIn">
            <app-loading-bar></app-loading-bar>
            <app-navigation></app-navigation>
            <app-header></app-header>
            <app-notifications></app-notifications>

            <div class="cscPage">
                <main class="cscContent">
                    <router-view></router-view>
                </main>

                <app-footer></app-footer>
            </div>
        </template>

        <logout-screen v-else></logout-screen>
    </div>
</template>

<script>
import AppFooter from '~/components/core/AppFooter';
import AppHeader from '~/components/core/header/AppHeader';
import AppLoadingBar from '~/components/core/AppLoadingBar';
import AppNavigation from '~/components/core/navigation/AppNavigation';
import AppNotifications from '~/components/core/AppNotifications';
import LogoutScreen from '~/components/core/splash/LogoutScreen';
import { DatePicker } from '@odm-operations-tooling/odm-frontend-cockpit-ui';

import BrowserDetection from '~/util/browserDetection';

import * as Authentication from '~/util/authentication';
export default {
    name: 'App',

    components: {
        AppFooter,
        AppHeader,
        AppLoadingBar,
        AppNavigation,
        AppNotifications,
        LogoutScreen,
        // eslint-disable-next-line vue/no-unused-components
        DatePicker // to include vuejs-datepicker in the vendor bundle,
    },
    computed: {
        isMobile() {
            return BrowserDetection.isMobile();
        },
        isLoggedIn() {
            return Authentication.hasValidToken();
        }
    }
};
</script>
