<template>
    <div class="navigationMenuContent" :class="{ narrow: page.menu.length === 1, wide: page.menu.length === 2 }">
        <div v-for="column in page.menu" :key="column.id" :class="{ colFull: page.menu.length === 1, colHalf: page.menu.length === 2 }">
            <div v-for="group in column" :key="group.id" class="itemGroup">
                <template v-if="group.children">
                    <div v-if="group.children.length > 0">
                        <span>{{ group.name }}:</span>

                        <ul>
                            <li class="clickableItem" v-for="child in group.children" :key="child.id">
                                <a :href="getPageHref(page, child)" @click.prevent="onMenuItemClicked(page, child)">
                                    {{ child.name }}
                                    <!--
                                    <template v-if="child.labels"> label feature introduced with CSBI-2406. For now labels are still hidden
                                        <menu-item-label v-for="label in child.labels" :key="label" :label="label"></menu-item-label>
                                     </template>
                                    -->
                                </a>
                            </li>
                        </ul>
                    </div>
                </template>
                <a v-else :href="getPageHref(page, group)" class="clickableItem" @click.prevent="onMenuItemClicked(page, group)">
                    {{ group.name }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
const MENU_ITEM_PLACEHOLDER = ':menuitem';

export default {
    name: 'NavigationMenuContent',
    components: {},
    props: {
        page: {
            type: Object,
            required: true
        }
    },

    methods: {
        getPageHref(page, child) {
            return page.href.replace(MENU_ITEM_PLACEHOLDER, child.id);
        },

        onMenuItemClicked(page, item) {
            // Ignore clicks on headlines

            if (!item.menu) {
                this.$emit('navigate', page, item);
            }
        }
    }
};
</script>

<style lang="scss">
.navigationMenuContent {
    padding: 15px 0 15px 15px;
    margin: 0;
    border-right: 1px solid $color_bootstrap_btn_border;
    border-bottom: 1px solid $color_bootstrap_btn_border;
    display: flex;

    .colHalf,
    .colFull {
        padding-left: 15px;
        padding-right: 15px;
    }
    .colHalf {
        width: 50%;
    }
    .colFull {
        width: 100%;
    }

    &.narrow {
        width: 400px;
    }
    &.wide {
        width: 600px;
    }

    ul {
        margin: 0;
        padding-left: 30px;
    }
    li {
        list-style-type: none;
        display: inline-block;
        font-weight: normal;
        margin: 2px 0;
    }
    a,
    a:hover {
        color: inherit;
        text-decoration: none;
    }

    .itemGroup {
        margin-bottom: 15px;

        span {
            display: inline-block;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .clickableItem {
        display: block;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        &:before {
            font-family: 'FontAwesome';
            content: '\f0da';
            float: left;
            padding-right: 9px;
            margin-top: 3px;
            text-decoration: underline;
        }

        // Fix underlined :before bug in IE, see http://stackoverflow.com/a/21902566/1350193
        &:before,
        &:hover:before {
            text-decoration: none;
        }
    }
}

@media (max-height: 670px) {
    .navigationMenuContent .itemGroup {
        margin-bottom: 10px;
    }
}
</style>
