import RouterProvider from '~/router';

export default {
    getQuery() {
        const $router = RouterProvider.getRouter();
        if ($router === null) {
            return '';
        }
        return $router.history.current.query;
    },

    updateQuery(query) {
        const $router = RouterProvider.getRouter();
        $router.replace({ query });
    },

    // Note that paths relative to the root path "#/" must start with "/", otherwise Vue router understands the new path as relative to the old one

    updatePath(path, query = {}) {
        const $router = RouterProvider.getRouter();
        $router.replace({ path, query });
    },

    get queryWatcherMixin() {
        return {
            mounted() {
                // This works because a hash change from Vue Router is done with replaceState and does not trigger this event.
                // Hence, only manual URL changes will trigger this listener.

                window.addEventListener('hashchange', this.deepLinksHashChangeListener);
            },
            beforeDestroy() {
                window.removeEventListener('hashchange', this.deepLinksHashChangeListener);
            },
            methods: {
                onDeepLinksQueryChanged() {}, // to be overridden in components
                deepLinksHashChangeListener() {
                    this.onDeepLinksQueryChanged();
                }
            }
        };
    }
};
