<template>
    <div class="legacyHeaderButtons">
        <slot></slot>

        <header-ghost-button
            v-for="button in filteredButtons"
            :key="button.type"
            :icon="button.icon"
            :small="isSmall"
            @click="buttonClicked(button.type)"
        >
            <template v-if="button.type === 'fleet' && currentFleet">{{ currentFleet.fleetId }}</template>
            <template v-else>{{ button.label }}</template>
        </header-ghost-button>

        <header-ghost-button
            v-if="isSmall && isAnyButtonVisible"
            class="ghostButton sidebarToggle"
            icon="fa-bars"
            @click="sidebarToggleClicked"
        >
        </header-ghost-button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IFrameBridge from '@odm-operations-tooling/iframe-bridge';
import { BRIDGE_EVENTS } from '@odm-operations-tooling/cockpit-commons';

import HeaderGhostButton from '~/components/core/header/HeaderGhostButton';
import filterButtonActive from '~/components/core/header/mixins/filterButtonActive';

import * as Authorization from '~/util/authorization';

export default {
    name: 'LegacyHeaderButtons',

    components: {
        HeaderGhostButton
    },

    mixins: [filterButtonActive],

    data() {
        const getButtonConfig = (type, icon) => {
            return { type, label: this.$t('core.header.buttons.' + type), icon };
        };

        return {
            isSmall: document.body.clientWidth < 1280, // first estimation until inner Cockpit loads
            buttons: [
                getButtonConfig('filters', 'fa-filter'),
                getButtonConfig('fleet', 'fa-globe'),
                getButtonConfig('incidents', 'fa-calendar')
            ]
        };
    },

    computed: {
        ...mapGetters('views', ['activeView']),
        ...mapGetters('fleets', ['currentFleet', 'fleets']),
        filteredButtons() {
            return this.buttons.filter(button => this.isVisible(button.type));
        },

        isAnyButtonVisible() {
            return this.filteredButtons.length > 0;
        }
    },

    created() {
        this.windowResizeListenerId = IFrameBridge.registerEventListener(BRIDGE_EVENTS.WINDOW_RESIZED_IS_SMALL, isSmall => {
            this.isSmall = isSmall;
        });

        this.fleetListenerId = IFrameBridge.registerEventListener(BRIDGE_EVENTS.FLEET_CURRENT_FILTER, fleetId => {
            const fleet = this.fleets.find(f => f.fleetId === fleetId);
            this.$store.dispatch('fleets/setCurrentFleet', {
                ...fleet
            });
        });
    },

    destroyed() {
        IFrameBridge.unregisterEventListener(this.windowResizeListenerId);
        IFrameBridge.unregisterEventListener(this.fleetListenerId);
    },

    methods: {
        getIFrame() {
            const frame = document.querySelector('.legacyCockpitFrame');
            if (!frame) {
                // Oops, this shouldn't happen

                throw 'No iframe with the Angular Cockpit found to handle the header button click request. This is an implementation error.';
            }
            return frame;
        },

        buttonClicked(type) {
            const frame = this.getIFrame();
            IFrameBridge.triggerEventInIFrame(frame, BRIDGE_EVENTS.HEADER_BUTTON_CLICKED, type);
        },

        sidebarToggleClicked() {
            const frame = this.getIFrame();
            IFrameBridge.triggerEventInIFrame(frame, BRIDGE_EVENTS.HEADER_BUTTON_CLICKED, 'sidebar');
        },

        isVisible(type) {
            switch (type) {
                case 'fleet':
                    return this.isLegacyFleetFilterActive;

                case 'incidents':
                    if (!Authorization.isAllowed('csc_calendar')) {
                        return false;
                    }
                    break;
            }

            const isPropertyPresent = obj => obj && obj.hasOwnProperty('headerButtons') && obj.headerButtons.hasOwnProperty(type);
            if (this.activeView) {
                for (const key of ['item', 'parent', 'tab']) {
                    if (isPropertyPresent(this.activeView[key])) {
                        return this.activeView[key].headerButtons[type];
                    }
                }
            }
            return false;
        }
    }
};
</script>

<style lang="scss">
.legacyHeaderButtons {
    .sidebarToggle {
        margin-left: 12px;
    }
}
</style>
