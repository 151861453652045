import Vue from 'vue';

import { EnvironmentService, ErrorMessageService } from '@odm-operations-tooling/cockpit-commons';

import Notifications from '~/util/notifications';
import Events from '~/assets/events';
import axios from 'axios';

// to prevent that the noCache param is added to the request add part of url ( subdomain preferred ) here
const cacheBlacklist = ['odm-pickup-dropoff'];

function addCacheBuster(path) {
    let url = path;
    const cacheBusterDate = +new Date();
    const newCacheBuster = 'noCache=' + cacheBusterDate;
    const existingCacheBuster = url.match(/noCache=([0-9]*)/);
    if (existingCacheBuster) {
        url = url.replace(existingCacheBuster[0], newCacheBuster);
    } else {
        url += (url.includes('?') ? '&' : '?') + newCacheBuster;
    }
    return url;
}

let runningRequests = 0;

function increaseRunningRequests() {
    ++runningRequests;
    if (runningRequests > 0) {
        Vue.prototype.$eventBus.$emit(Events.requestInProgress, true);
    }
}

function decreaseRunningRequests() {
    --runningRequests;
    if (runningRequests <= 0) {
        Vue.prototype.$eventBus.$emit(Events.requestInProgress, false);
    }
}

async function retryHandler(performRequest) {
    let result;
    increaseRunningRequests();

    try {
        // First try

        result = await performRequest();
        decreaseRunningRequests();
        return result;
    } catch (error) {
        console.error(error); // eslint-disable-line
        console.info('Retrying failed request...'); // eslint-disable-line
    }

    // Retry

    try {
        return await performRequest();
    } catch (e) {
        throw e;
    } finally {
        decreaseRunningRequests();
    }
}

function errorHandler(error, showErrorToast = true) {
    const { message, info } = ErrorMessageService.buildErrorInfo(error.response);

    if (showErrorToast) {
        Notifications.showError(message, false, false, info);
    }
    return { message, info };
}

async function download(path, additionalHeaders, showErrorToast = true, silent = false, useDefaultErrorHandler = true) {
    const config = { responseType: 'arraybuffer', headers: additionalHeaders };

    if (silent) {
        try {
            const result = await axios.get(path, config);
            return result.data;
        } catch (e) {
            throw 'Error during file download: ' + path;
        }
    } else {
        try {
            const result = await retryHandler(() => axios.get(path, config));
            return result.data;
        } catch (error) {
            if (useDefaultErrorHandler) {
                throw errorHandler(error, showErrorToast);
            }
            console.info(error); // eslint-disable-line
            return {};
        }
    }
}

async function get(path, params = undefined, showErrorToast = true, useDefaultErrorHandler = true, retry = false) {
    try {
        let result = null;
        if (retry) {
            result = await retryHandler(() => axios.get(path, { params }));
        } else {
            result = await axios.get(path, { params });
        }
        return result.data;
    } catch (error) {
        if (useDefaultErrorHandler) {
            throw errorHandler(error, showErrorToast);
        }
        console.info(error); // eslint-disable-line
        return {};
    }
}

async function post(
    path,
    data = undefined,
    showErrorToast = true,
    multipart = false,
    additionalHeaders = {},
    useDefaultErrorHandler = true
) {
    let result;
    try {
        if (multipart) {
            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            result = await retryHandler(() => axios.post(path, data, config));
        } else {
            result = await retryHandler(() => axios.post(path, data, { headers: additionalHeaders }));
        }

        return result.data;
    } catch (error) {
        if (useDefaultErrorHandler) {
            throw errorHandler(error, showErrorToast);
        }
        console.info(error); // eslint-disable-line
        return {};
    }
}

async function patch(
    path,
    data = undefined,
    showErrorToast = true,
    multipart = false,
    additionalHeaders = {},
    useDefaultErrorHandler = true
) {
    let result;
    try {
        if (multipart) {
            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            result = await retryHandler(() => axios.patch(path, data, config));
        } else {
            result = await retryHandler(() => axios.patch(path, data, { headers: additionalHeaders }));
        }

        return result.data;
    } catch (error) {
        if (useDefaultErrorHandler) {
            throw errorHandler(error, showErrorToast);
        }
        console.info(error); // eslint-disable-line
        return {};
    }
}

async function put(path, data = undefined, showErrorToast = true, useDefaultErrorHandler = true) {
    try {
        const result = await retryHandler(() => axios.put(path, data));
        return result.data;
    } catch (error) {
        if (useDefaultErrorHandler) {
            throw errorHandler(error, showErrorToast);
        }
        console.info(error); // eslint-disable-line
        return {};
    }
}

async function deleteRequest(path, data = undefined, showErrorToast = true, useDefaultErrorHandler = true) {
    try {
        const params = {
            data
        }; // see https://github.com/axios/axios/issues/897#issuecomment-343715381
        const result = await retryHandler(() => axios.delete(path, params));
        return result.data;
    } catch (error) {
        if (useDefaultErrorHandler) {
            throw errorHandler(error, showErrorToast);
        }
        console.info(error); // eslint-disable-line
        return {};
    }
}

function init(IDToken = null) {
    axios.interceptors.request.use(
        config => {
            if (!EnvironmentService.isLocal() && !cacheBlacklist.some(r => config.url.includes(r))) {
                config.url = addCacheBuster(config.url);
            }
            return {
                ...config,
                headers: {
                    IDToken,
                    ...config.headers
                }
            };
        },
        error => Promise.reject(error)
    );
}

export default {
    init,
    get,
    post,
    patch,
    put,
    download,
    delete: deleteRequest
};
