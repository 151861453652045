<template>
    <footer class="appFooter">
        <div class="feedbackHint" v-if="showFeedback">
            <i class="fa fa-commenting" aria-hidden="true"></i> {{ $t('core.footer.feedback') }}
        </div>

        <div class="rightAligned" v-if="info">v{{ info.version }} @ {{ info.environment }}</div>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'AppFooter',

    computed: {
        ...mapGetters(['info']),

        showFeedback() {
            return this.$route.path.startsWith('/fleet');
        }
    },

    created() {
        this.$store.dispatch('loadInfo');
    }
};
</script>

<style lang="scss">
.appFooter {
    height: $csc_footer_height;
    color: $color_gray_nero;
    font-size: 14px;

    .feedbackHint {
        color: $color_ci_blue_shade1;
        float: left;

        .fa {
            margin-right: 4px;
        }
    }

    .rightAligned {
        float: right;
    }
}
</style>
