import { Util } from '@odm-operations-tooling/cockpit-commons';

import $store from '~/store';

const BASE_WARNING = 'FleetCategory: Cannot check if the fleet {0} is a ridehailing fleet because ';

export default {
    isRidehailingFleet(fleetId) {
        if (!fleetId) {
            return false;
        }

        const fleets = $store.getters['fleets/fleets'];
        if (!fleets) {
            console.warn(Util.string.format(BASE_WARNING, fleetId) + 'fleets are not available.'); // eslint-disable-line
            return false;
        }

        const fleet = fleets.find(entry => entry.fleetId === fleetId);
        if (!fleet) {
            console.warn(Util.string.format(BASE_WARNING, fleetId) + 'a matching fleet object was not found in fleet list.'); // eslint-disable-line
            return false;
        }

        return fleet.isRidehailing;
    },

    isUSWestCoastFleet(fleetId) {
        return ['US-ORPDX', 'US-SEA'].includes(fleetId);
    }
};
