import $store from '~/store';

export function isAllowed(permission) {
    try {
        return $store.getters['permissions/permissions'].includes(permission);
    } catch (e) {
        console.error(e); //eslint-disable-line
        return false;
    }
}

export function isFleetAllowed(fleet) {
    return $store.getters['permissions/fleets'].includes(fleet);
}
