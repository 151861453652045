import { Util } from '@odm-operations-tooling/cockpit-commons';
import { importMoment } from '~/assets/vendor';
import { importBootstrap } from '@odm-operations-tooling/odm-frontend-cockpit-ui/src/assets/vendor';
import ExternalApplicationFrame from '~/components/core/ExternalApplicationFrame';
import SubPage from '~/components/core/SubPage';

const SUBPAGE_PARAM = '/:menuitem';

// This workaround is necessary because we do not live in a Vue-only world yet.
// VIEWS in CCC has to support both Angular and Vue router, hence we have to add Vue-specific things here.

function rewriteComponentForVueSupport(id, route, viewParams = undefined) {
    if (viewParams && viewParams.menu && !viewParams.iframeUrl) {
        route.component = SubPage;
        route.path = Util.string.remove(SUBPAGE_PARAM, route.path);
        route.children = createChildRoutes(viewParams); // eslint-disable-line
        return;
    }

    switch (id) {
        case 'csc_external_tools':
            route.component = function() {
                return import(/* webpackChunkName: "externalTools" */ '~/components/externalTools/ExternalTools');
            };
            break;

        case 'csc_tasks':
            route.component = async function() {
                await Promise.all([importBootstrap(), importMoment()]);
                return import(/* webpackChunkName: "tasks" */ '~/components/tasks/TasksTabSetup');
            };
            break;

        case 'csc_tasks_mobile':
            route.component = async function() {
                await Promise.all([importBootstrap(), importMoment()]);
                return import(/* webpackChunkName: "tasksMobile" */ '~/components/tasks/mobile/TasksTabSetup');
            };
            break;

        case 'rh_trips':
            route.component = async function() {
                await Promise.all([importBootstrap(), importMoment()]);
                return import(/* webpackChunkName: "ridehailing" */ '~/components/ridehailing/trips/RidehailingTripsList');
            };
            break;

        case 'administration_user_preferences':
            route.component = function() {
                return import(/* webpackChunkName: "administration" */ '~/components/administration/userPreferences/UserPreferences');
            };
            break;

        case 'administration_manage_notifications':
            route.component = async function() {
                await Promise.all([importBootstrap()]);
                return import(
                    /* webpackChunkName: "administration" */ '~/components/administration/notificationManagement/NotificationManagement'
                );
            };
            break;

        case 'administration_fleets_view':
            route.component = async function() {
                await Promise.all([importBootstrap(), importMoment()]);
                return import(/* webpackChunkName: "fleetData" */ '~/components/fleetData/fleetConfiguration/FleetConfigurationSetup');
            };
            break;

        case 'fleet_data_vehicle_access_location_view':
            route.component = async function() {
                await Promise.all([importBootstrap()]);
                return import(
                    /* webpackChunkName: "fleetData" */ '~/components/fleetData/vehicleAccessLocation/VehicleAccessLocationSetup'
                );
            };
            break;

        case 'administration_vehicle_display_view':
            route.component = async function() {
                await importBootstrap();
                return import(/* webpackChunkName: "administration" */ '~/components/administration/vehicleDisplay/DisplayedVehicleData');
            };
            break;

        case 'administration_alert_settings':
            route.component = async function() {
                await Promise.all([importBootstrap(), importMoment()]);
                return import(
                    /* webpackChunkName: "administration" */ '~/components/administration/alert/alertSettings/AlertSettingsSetup'
                );
            };
            break;

        case 'administration_alert_notifications':
            route.component = async function() {
                await Promise.all([importBootstrap(), importMoment()]);
                return import(
                    /* webpackChunkName: "administration" */ '~/components/administration/alert/alertNotificationSettings/AlertNotificationSettings'
                );
            };
            break;
        case 'administration_messages':
            route.component = async function() {
                await Promise.all([importBootstrap()]);
                return import(/* webpackChunkName: "adminMessages" */ '~/components/administration/adminMessages/AdminMessages');
            };
            break;

        case 'p2p_administration':
            route.component = async function() {
                await Promise.all([importBootstrap(), importMoment()]);
                return import(/* webpackChunkName: "p2pTripAdministration" */ '~/components/p2p/administration/TripAdministrationSetup');
            };
            break;

        case 'csc_operations_fleet':
            route.component = async function() {
                await Promise.all([importBootstrap(), importMoment()]);
                return import(/* webpackChunkName: "fleetManagementTab" */ '~/components/fleetManagement/FleetManagementPageSetup');
            };
            break;
    }
}

function createChildRoutes(viewParams) {
    const childRoutes = [];

    const routeFromItem = item => ({
        name: item.name,

        // Wildcard check will need to be more sophisticated...

        path: item.id + (item.id === 'external_member_emea_core' ? '/(.*)?' : ''),
        meta: {
            id: item.id
        },
        component: ExternalApplicationFrame
    });

    for (const item of viewParams.menu) {
        const isGroup = !!item.children;
        if (!isGroup) {
            const route = routeFromItem(item);
            rewriteComponentForVueSupport(item.id, route);
            childRoutes.push(route);
        } else {
            for (const child of item.children) {
                const route = routeFromItem(child);
                rewriteComponentForVueSupport(child.id, route);
                childRoutes.push(route);
            }
        }
    }

    return childRoutes;
}

export default function createRoute(viewParams) {
    const route = {
        name: viewParams.title,
        path: viewParams.href.substring(1),
        meta: {
            id: viewParams.id
        },
        component: ExternalApplicationFrame
    };

    rewriteComponentForVueSupport(viewParams.id, route, viewParams);

    return route;
}
