import { VIEWS, Util } from '@odm-operations-tooling/cockpit-commons';

import { COCKPIT_URL } from '~/assets/config/legacyCockpitURLs';

const SUBPAGE_PARAM = ':menuitem?';

const iFrameMapping = {};

function hasChildren(id) {
    return VIEWS.menus[id] && VIEWS.menus[id].filter(item => !item.noview).length > 0;
}

VIEWS.tabs.forEach(tab => {
    if (hasChildren(tab.id)) {
        VIEWS.menus[tab.id].forEach(view => {
            if (!view.noview && tab.href) {
                if (view.children) {
                    view.children.forEach(child => {
                        if (child.iframeUrl) {
                            iFrameMapping[child.id] = child.iframeUrl;
                        } else {
                            iFrameMapping[child.id] = COCKPIT_URL + Util.string.replace(SUBPAGE_PARAM, child.id, tab.href);
                        }
                    });
                } else {
                    if (view.iframeUrl) {
                        iFrameMapping[view.id] = view.iframeUrl;
                    } else {
                        iFrameMapping[view.id] = COCKPIT_URL + Util.string.replace(SUBPAGE_PARAM, view.id, tab.href);
                    }
                }
            }
        });
    } else {
        if (tab.iframeUrl) {
            iFrameMapping[tab.id] = tab.iframeUrl;
        } else {
            iFrameMapping[tab.id] = COCKPIT_URL + tab.href;
        }
    }
});

export default iFrameMapping;
