import { createAdminMessage } from '~/business/comprehensive/adminMessage/AdminMessageFactory';

import BrowserDetection from '~/util/browserDetection';
import { MESSAGE_TYPE_KEYS } from '~/business/comprehensive/adminMessage/messageTypes';

export default [
    createAdminMessage({
        viewId: '*',
        type: MESSAGE_TYPE_KEYS.WARNING,
        condition: () => !BrowserDetection.isSupportedBrowser(),
        message: `<b>You seem to use an unsupported browser.</b><br>
        Supported browsers are Chrome (latest) and Firefox (latest).`
    })
];
