/* eslint-disable no-use-before-define */

const PING_INTERVAL_TIME = 5000;
const SOCKET_TIMEOUT = 11000;

const OPEN_STATE_CODE = 1;
const CLOSING_STATE_CODE = 2;

function getWebsocketUrl(path) {
    // self is used so that the socketFactory can also run in webWorker
    const protocol = self.location.protocol === 'http:' ? 'ws:' : 'wss:';
    return `${protocol}//${self.location.host}/${path}`;
}

function createSocket(url, initialMessage, onMessageCallback, onClearCallback = null) {
    let socket = null;
    let pingInterval;
    let lastPingTimestamp;

    function initializeSocket() {
        socket = new WebSocket(getWebsocketUrl(url));

        socket.onopen = function() {
            if (initialMessage) {
                send(initialMessage);
            }
        };

        socket.onmessage = function({ data }) {
            switch (data) {
                case 'pong':
                    lastPingTimestamp = Date.now();
                    break;

                case 'clear':
                    if (onClearCallback) {
                        onClearCallback();
                    } else {
                        console.warn(`SocketFactory: Missing callback for "clear" message received for ${socket.url}.`); // eslint-disable-line
                    }

                    break;

                default:
                    const parsedData = JSON.parse(data);
                    onMessageCallback(parsedData);
                    break;
            }
        };

        socket.onerror = function(e) {
            console.error('SocketFactory: A WebSocket error occurred.', e); // eslint-disable-line

            closeSocket();
            setTimeout(function() {
                openSocket();
            }, PING_INTERVAL_TIME);
        };

        socket.onclose = function() {};
    }

    function initializePing() {
        lastPingTimestamp = Date.now();

        pingInterval = setInterval(function() {
            const now = Date.now();
            if (now - lastPingTimestamp > SOCKET_TIMEOUT) {
                try {
                    closeSocket();
                } catch (e) {
                    console.error('SocketFactory: Error because of WebSocket timeout.', e); // eslint-disable-line
                }
                openSocket();
            }
            if (socket.readyState === OPEN_STATE_CODE) {
                socket.send('ping');
            }
        }, PING_INTERVAL_TIME);
    }

    function openSocket() {
        if (socket) {
            closeSocket();
        }

        initializeSocket();
        initializePing();
    }

    function closeSocket() {
        clearInterval(pingInterval);
        if (onClearCallback) {
            onClearCallback();
        }

        if (socket && socket.readyState < CLOSING_STATE_CODE) {
            socket.close();
        }

        socket = null;
    }

    function isOpen() {
        return socket !== null && socket.readyState === OPEN_STATE_CODE;
    }

    function send(data) {
        if (isOpen()) {
            socket.send(data);
        }
    }

    return {
        openSocket,
        closeSocket,
        isOpen,
        send
    };
}

export { createSocket };
