// Safe way of getting global parameters injected by webpack at build time

/* eslint-disable no-undef, no-empty */

export default {
    isProductionEnv() {
        try {
            return process.env.NODE_ENV === 'production';
        } catch (e) {}
        return false;
    },

    getBuildDate() {
        try {
            return BUILD_DATE;
        } catch (e) {}
        return Date.now();
    }
};
