<template>
    <span class="legacyFleetFilterInfo">
        <span class="trips" v-if="showTripsCount" :title="$t('core.header.info.trips')">
            {{ numberOfTrips }} <i class="fa fa-location-arrow" aria-hidden="true"></i>
        </span>

        <span class="cars" :title="$t('core.header.info.cars')"> {{ numberOfCars }} <i class="fa fa-car" aria-hidden="true"></i> </span>
    </span>
</template>

<script>
import IFrameBridge from '@odm-operations-tooling/iframe-bridge';
import { BRIDGE_EVENTS } from '@odm-operations-tooling/cockpit-commons';

import FleetCategory from '~/util/fleetCategory';

export default {
    name: 'LegacyFleetFilterInfo',

    data() {
        return {
            currentFleet: null,
            numberOfTrips: 0,
            numberOfCars: 0
        };
    },

    computed: {
        showTripsCount() {
            return FleetCategory.isRidehailingFleet(this.currentFleet);
        }
    },

    created() {
        this.filterInfoListenerId = IFrameBridge.registerEventListener(BRIDGE_EVENTS.FLEET_FILTER_INFO, info => {
            if (info.hasOwnProperty('numberOfTrips')) {
                this.numberOfTrips = info.numberOfTrips;
            }
            if (info.hasOwnProperty('numberOfCars')) {
                this.numberOfCars = info.numberOfCars;
            }
        });

        this.fleetListenerId = IFrameBridge.registerEventListener(BRIDGE_EVENTS.FLEET_CURRENT_FILTER, fleet => {
            this.currentFleet = fleet;
        });
    },

    destroyed() {
        IFrameBridge.unregisterEventListener(this.filterInfoListenerId);
        IFrameBridge.unregisterEventListener(this.fleetListenerId);
    }
};
</script>

<style lang="scss">
.legacyFleetFilterInfo {
    display: inline-block;
    margin-right: 2px;
    line-height: 35px;
    color: $color_gray_nero;
    font-weight: bold;

    .trips,
    .cars {
        display: inline-block;
    }

    .trips {
        margin-right: 10px;
    }

    .cars .fa {
        margin-left: 3px;
    }
}
</style>
