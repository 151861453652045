import { getUser } from '~/util/authentication';

export default {
    namespaced: true,

    state: {
        user: null
    },

    getters: {
        user: state => state.user,
        displayName: state => (state.user ? state.user.displayName : null),
        username: state => (state.user ? state.user.username : null),
        uuid: state => (state.user ? state.user.uuid : null)
    },

    actions: {
        async getUser({ commit }) {
            commit('SET_USER', getUser());
        }
    },

    mutations: {
        SET_USER(state, user) {
            state.user = user;
        }
    }
};
