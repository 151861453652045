<template>
    <button :class="{ headerGhostButton: true, small, disabled }" :disabled="disabled" @click="onClicked">
        <i :class="['fa', icon]"></i>
        <span class="label" v-if="$slots.default">
            <slot></slot>
        </span>
    </button>
</template>

<script>
export default {
    name: 'HeaderGhostButton',

    props: {
        icon: {
            type: String,
            required: true
        },
        small: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        onClicked() {
            this.$emit('click'); // make use of native modifier unnecessary
        }
    }
};
</script>

<style lang="scss">
.headerGhostButton {
    background: $color_white;
    border: 1px solid $color_ci_blue_shade1;
    color: $color_ci_blue_shade1;
    font-size: 16px;
    font-family: inherit;
    font-weight: bold;
    padding: 4px 12px;
    margin-left: 15px;
    margin-top: 1px;
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    line-height: 1.4;
    border-radius: 4px;
    user-select: none;

    &.disabled {
        cursor: not-allowed;
        border-color: $color_gray;
        color: $color_gray;
    }

    &:not(.disabled):hover {
        background: $color_ci_blue_shade1;
        border-color: $color_white;
        color: $color_white;
    }

    .fa {
        font-size: 20px;
    }
    .label {
        margin-left: 5px;
    }

    &.small .label {
        display: none;
    }
}
</style>
