const UA = navigator.userAgent.toLowerCase();

function isFirefox() {
    return UA.includes('firefox');
}

function isEdge() {
    return UA.includes('chrome') && UA.includes('edge');
}

function isMobile() {
    return UA.includes('mobile');
}

export default {
    isFirefox,
    isEdge,
    isMobile,

    isSupportedBrowser() {
        if (UA.includes('iphone os')) {
            return true;
        }

        //

        if (UA.includes('chrome') || isFirefox()) {
            return true;
        }
        return false;
    }
};
