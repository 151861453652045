import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import storeDefinition from '~/store/core';

import { localStorageAdapterPlugin } from '~/store/plugins/localStorageStorePlugin';
storeDefinition.plugins = [localStorageAdapterPlugin];

export default new Vuex.Store(storeDefinition);
