import { Util, EnvironmentService, EXTERNAL_TOOL_LINKS } from '@odm-operations-tooling/cockpit-commons';

let ENVIRONMENT = EnvironmentService.getEnvironment();
if (!['TEST', 'INT', 'PROD'].includes(ENVIRONMENT)) {
    ENVIRONMENT = 'PROD';
}

EXTERNAL_TOOL_LINKS.forEach(tool => {
    let url = tool.urls.PROD;

    // Note that PROD url is always available

    if (ENVIRONMENT === 'TEST') {
        url = tool.urls.TEST || tool.urls.INT || url;
    } else if (ENVIRONMENT === 'INT') {
        url = tool.urls.INT || url;
    }

    tool.link = url;
});

Util.array.sortByKey(EXTERNAL_TOOL_LINKS, 'name');

export default {
    getTools: () => EXTERNAL_TOOL_LINKS,

    getToolLink(toolName) {
        const tool = EXTERNAL_TOOL_LINKS.find(e => e.name === toolName);
        if (!tool) {
            return '';
        }
        return tool.link;
    }
};
