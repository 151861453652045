<template>
    <div class="subPage" :key="activeId">
        <router-view></router-view>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'SubPage',

    computed: {
        ...mapGetters('views', ['activeId'])
    }
};
</script>
