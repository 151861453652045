<template>
    <header class="appHeader" ref="header">
        <router-link to="/" class="title">
            <cockpit-title></cockpit-title>
        </router-link>

        <div class="rightAligned">
            <portal-target name="appHeaderFilter"></portal-target>

            <legacy-header-buttons>
                <legacy-fleet-filter-info v-if="isLegacyFleetFilterActive"></legacy-fleet-filter-info>
            </legacy-header-buttons>

            <div class="sidebarElements">
                <search-field class="search"></search-field>
                <logout-button></logout-button>
            </div>
        </div>
    </header>
</template>

<script>
import CockpitTitle from '~/components/core/CockpitTitle';
import LegacyFleetFilterInfo from '~/components/core/header/LegacyFleetFilterInfo';
import LegacyHeaderButtons from '~/components/core/header/LegacyHeaderButtons';
import LogoutButton from '~/components/core/header/LogoutButton';
import SearchField from '~/components/core/header/SearchField';
import filterButtonActive from '~/components/core/header/mixins/filterButtonActive';

export default {
    name: 'AppHeader',

    components: {
        CockpitTitle,
        LegacyFleetFilterInfo,
        LegacyHeaderButtons,
        LogoutButton,
        SearchField
    },

    mixins: [filterButtonActive],

    mounted() {
        const onScroll = () => {
            const isPageScrolled = window.scrollY > 15;
            this.$refs.header.classList[isPageScrolled ? 'add' : 'remove']('materialShadow');
        };

        window.addEventListener('scroll', onScroll);
        onScroll();
    }
};
</script>

<style lang="scss">
.appHeader {
    $top_bottom_padding: 15px;

    width: 100%;
    padding: $top_bottom_padding $csc_page_padding $top_bottom_padding $csc_nav_default_width + $csc_page_padding;
    position: fixed;
    top: 0;
    z-index: $z-index-app-header;
    background: $color_white;
    transition: box-shadow 0.3s;
    will-change: box-shadow;
    min-width: $csc_min_page_width;

    &.materialShadow {
        box-shadow: 0 0 10px transparentBlack(0.3);
    }

    .title {
        text-decoration: none;
        cursor: pointer;
        display: inline-block;
        margin-top: 1px;
    }

    .rightAligned {
        float: right;
        display: flex;
    }

    .search {
        margin-right: 5px;
        width: 100%;
    }

    .sidebarElements {
        width: 30vw;
        min-width: 270px;
        margin-left: 30px;
        display: flex;
    }

    @mixin sidebarElementsWidth($nav_width) {
        width: calc((100vw - #{$nav_width}) * 0.33 - 35px); // to match Bootstrap's col-sm-4 in the Angular Cockpit
    }

    body.showNavBarLabels & {
        padding-left: $csc_nav_default_width + $csc_nav_labels_width + $csc_page_padding; // also account for labels bar

        .sidebarElements {
            width: calc((100vw - #{$csc_nav_labels_width}) * 0.3);
        }
    }
    body.legacyCockpitFrameActive & {
        .sidebarElements {
            @include sidebarElementsWidth($csc_nav_default_width);
        }
    }
    body.legacyCockpitFrameActive.showNavBarLabels & {
        .sidebarElements {
            @include sidebarElementsWidth($csc_nav_labels_width + $csc_nav_default_width);
        }
    }
}
</style>
