<template>
    <span :class="{ cockpitTitle: true, large, medium }">
        <i class="fa fa-square blue" aria-hidden="true"></i>
        ODM <span class="blue">Cockpit</span>
    </span>
</template>

<script>
export default {
    name: 'CockpitTitle',

    props: {
        large: {
            type: Boolean,
            default: false
        },

        medium: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss">
.cockpitTitle {
    color: $color_black;
    font-weight: bold;
    white-space: nowrap;
    user-select: none;
    font-size: 24px;
    letter-spacing: -0.02em;

    &.large {
        font-size: 63px;
    }
    &.medium {
        font-size: 44px;
    }

    .blue {
        color: $color_ci_smalt;
    }
}

@media (max-width: $screen-lg) {
    .cockpitTitle {
        &.large {
            font-size: 55px;
        }
        &.medium {
            font-size: 36px;
        }
    }
}
</style>
