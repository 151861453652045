import IFrameBridge from '@odm-operations-tooling/iframe-bridge';
import { BRIDGE_EVENTS } from '@odm-operations-tooling/cockpit-commons';
import $store from '~/store';
import bmwLogo from '~/assets/icons/favicon.png';

async function showMessage(type, message, hideAfterTimeout = false, uniqueMessageText = false, info = undefined) {
    return await $store.dispatch('notifications/showMessage', {
        type,
        message,
        hideAfterTimeout,
        uniqueMessageText,
        info
    });
}

IFrameBridge.registerContentProvider(
    BRIDGE_EVENTS.NOTIFICATION,
    async function({ type, message, hideAfterTimeout = true, uniqueMessageText, info }) {
        return await showMessage(type, message, hideAfterTimeout, uniqueMessageText, info);
    },
    true
);

IFrameBridge.registerEventListener(BRIDGE_EVENTS.HIDE_NOTIFICATION, function(id) {
    if (id === 'all') {
        $store.dispatch('notifications/hideAllMessages');
    } else {
        $store.dispatch('notifications/hideMessage', id);
    }
});

IFrameBridge.registerEventListener(BRIDGE_EVENTS.DESKTOP_NOTIFICATION, function(desktopNotification) {
    if (Notification.permission !== 'granted') {
        Notification.requestPermission();
    } else {
        const notification = new Notification(desktopNotification.title, {
            icon: bmwLogo,
            body: desktopNotification.message
        });

        notification.onclick = function() {
            window.open(window.location.href);
        };
    }
});

export default {
    showError: showMessage.bind(null, 'error'),
    showWarning: showMessage.bind(null, 'warning'),
    showSuccess: showMessage.bind(null, 'success'),
    showInfo: showMessage.bind(null, 'info'),

    async hide(idPromise) {
        const id = await idPromise;
        $store.dispatch('notifications/hideMessage', id);
    },

    hideAll() {
        $store.dispatch('notifications/hideAllMessages');
    }
};
