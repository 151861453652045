<template>
    <div class="autocompleteField" :class="{ disabled }">
        <i :class="['fa', icon, 'icon']"></i>

        <input
            type="text"
            v-model="keyword"
            :placeholder="placeholder"
            :disabled="disabled"
            @input="onInput($event.target.value)"
            @blur="isDropdownShown = false"
            @keyup.esc="isDropdownShown = false"
            @keydown.down.stop.prevent="moveDown"
            @keydown.up.stop.prevent="moveUp"
            @keydown.enter="onSelect"
            ref="inputField"
        />
        <ul v-if="hasMatchedOptions" v-show="isDropdownShown" class="optionsList" :style="{ width: dropdownWidth }" ref="dropdown">
            <li
                v-for="(option, index) in matchedOptions"
                :key="option.getTitle()"
                :class="{ optionsItem: true, highlighted: index === highlightedPosition }"
                @mouseenter="highlightedPosition = index"
                @mousedown="onSelect"
            >
                <div class="optionsTitle" v-html="highlight(option.getTitle())"></div>
                <div class="optionsDescription" v-html="highlight(option.getDescription())" v-if="!noDescription"></div>
            </li>
        </ul>

        <ul v-else v-show="isDropdownShown" class="optionsList" :style="{ width: dropdownWidth }">
            <li class="optionsItem">
                <div class="optionsTitle">
                    <b>{{ $t('core.header.search.noResults') }}</b>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { Util } from '@odm-operations-tooling/cockpit-commons';

function highlightRecursive(nodes, term) {
    Array.from(nodes).forEach(node => {
        if (node.nodeType === node.TEXT_NODE) {
            if (node.textContent.toLowerCase().includes(term.toLowerCase())) {
                const termRegex = new RegExp(Util.string.escapeForRegExp(term), 'gi');
                const newText = node.textContent.replace(termRegex, match => `<span class="term">${match}</span>`);

                const newElement = document.createElement('span');
                newElement.innerHTML = newText;
                node.parentNode.insertBefore(newElement, node);
                node.parentNode.removeChild(node);
            }
        } else {
            highlightRecursive(node.childNodes, term);
        }
    });
}

function highlightSearchTerm(str, term) {
    const div = document.createElement('div');
    div.innerHTML = str;

    highlightRecursive(div.childNodes, term);
    return div.innerHTML;
}

export default {
    name: 'AutocompleteField',

    props: {
        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: 'fa-search'
        },
        noDescription: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        keepSelection: {
            type: Boolean,
            default: false
        },
        preSelectedOption: {
            type: String,
            required: false,
            default: null
        }
    },

    data() {
        return {
            isDropdownShown: false,
            highlightedPosition: 0,
            keyword: '',
            dropdownWidth: 0
        };
    },

    computed: {
        matchedOptions() {
            if (this.keyword.length < 2) {
                return [];
            }
            const regExp = new RegExp(Util.string.escapeForRegExp(this.keyword), 'i');
            return this.options.filter(option => option.doesMatch(this.keyword, regExp));
        },

        hasMatchedOptions() {
            return this.matchedOptions.length > 0;
        }
    },

    watch: {
        isDropdownShown() {
            if (this.isDropdownShown) {
                this.dropdownWidth = window.getComputedStyle(this.$refs.inputField).width;
            }
        }
    },

    created() {
        // Handle selection from the outside

        this.$on('select', this.doSelect);

        if (this.preSelectedOption) {
            this.doSelect(this.preSelectedOption);
        }
    },

    methods: {
        clear() {
            this.keyword = '';
            this.onInput(this.keyword);
            this.onSelect();
        },

        doSelect(keyword) {
            this.keyword = keyword;
            this.onInput(this.keyword);
            this.onSelect();
        },

        onInput(value) {
            this.highlightedPosition = 0;
            this.isDropdownShown = !!value && this.keyword.length >= 2;
            this.$emit('input', value);
        },

        onSelect() {
            const selectedItem = this.matchedOptions[this.highlightedPosition];
            this.isDropdownShown = false;
            if (selectedItem) {
                this.keyword = this.keepSelection ? selectedItem.getTitle() : '';
                this.$emit('selected', selectedItem);
            }
        },

        moveDown() {
            if (this.isDropdownShown) {
                this.highlightedPosition = (this.highlightedPosition + 1) % this.matchedOptions.length;
                this.adjustScroll();
            }
        },

        moveUp() {
            if (this.isDropdownShown) {
                this.highlightedPosition = this.highlightedPosition - 1 < 0 ? this.matchedOptions.length - 1 : this.highlightedPosition - 1;
                this.adjustScroll();
            }
        },

        adjustScroll() {
            this.$nextTick(() => {
                const highlightElement = this.$refs.dropdown.children[this.highlightedPosition];
                this.$refs.dropdown.scrollTop = highlightElement.offsetTop;
            });
        },

        highlight(str) {
            return highlightSearchTerm(str, this.keyword);
        }
    }
};
</script>

<style lang="scss">
@import '~@/assets/styles/components/iconTextField';

.autocompleteField {
    @include iconTextField();

    .optionsList {
        position: absolute;
        list-style-type: none;
        margin: 0;
        padding: 5px;
        max-height: 200px;
        overflow-x: hidden;
        overflow-y: scroll;
        font-size: 90%;
        background: $color_white;
        box-shadow: 1px 1px 4px $color_gray_very_light, -1px 1px 4px $color_gray_very_light;
        border-radius: 4px;
    }
    .optionsItem {
        padding: 6px;
        cursor: pointer;
    }
    .optionsDescription {
        margin: 4px 0 0 15px;
        font-size: 75%;
    }

    .highlighted {
        color: $color_white;
        background-color: $color_ci_blue_shade3;
    }
    .term {
        color: $color_white;
        background: $color_ci_smalt;
    }

    .label {
        display: inline-block;
        padding: 0.5em 0.6em;
        font-size: 80%;
        font-weight: 700;
        line-height: 1;
        color: $color_white;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25em;
        background-color: $color_gray_dim;
        margin-top: 4px;
    }
}
</style>
