import { EnvironmentService } from '@odm-operations-tooling/cockpit-commons';

import adminAlerts from '~/assets/config/adminAlerts';
import ComprehensiveServer from '~/server/comprehensive';

import persistent from '~/store/core/persistent';
import notifications from '~/store/core/notifications';
import permissions from '~/store/core/permissions';
import user from '~/store/core/user';
import views from '~/store/core/views';
import fleets from '~/store/comprehensive/fleets';

import BuildVariables from '~/util/buildVariables';

import * as AdminMessageMapper from '~/business/comprehensive/adminMessage/AdminMessageMapper';

export default {
    state: {
        info: null,
        packageVersion: process.env.VUE_APP_VERSION || '0',
        alerts: []
    },

    getters: {
        info: state => state.info,
        alerts: state => state.alerts
    },

    actions: {
        async loadInfo({ commit, state }) {
            const transformedInfo = {
                version: state.packageVersion,
                name: state.name,
                buildDate: new Date(BuildVariables.getBuildDate()).toString().substring(0, 24),
                environment: EnvironmentService.getEnvironment().toUpperCase()
            };

            if (BuildVariables.isProductionEnv()) {
                // eslint-disable-next-line no-console
                console.log('%cODM Cockpit', 'padding: 10px; border-radius: 8px; background: #5067a5; color: white; font-size: large');

                [
                    'Made with <3 by MaibornWolff',
                    '',
                    `Build date: ${transformedInfo.buildDate}`,
                    `Version: ${transformedInfo.version}`,
                    `Environment: ${transformedInfo.environment.toUpperCase()}`,

                    ''
                ].forEach(str => console.log(`${str}`)); // eslint-disable-line
            }

            commit('SET_INFO', transformedInfo);
        },

        async loadAlerts({ commit }) {
            let messages;
            try {
                messages = (await ComprehensiveServer.getAlerts()).messages;
            } catch (e) {
                return;
            }

            const mappedAlerts = messages
                .map(req => {
                    const alertObj = AdminMessageMapper.fromRequest(req);
                    alertObj.message = alertObj.message.replace(/\n/g, '<br>');
                    return alertObj;
                })
                .sort((a, b) => {
                    const aGlobal = a.viewId === '*';
                    const bGlobal = b.viewId === '*';
                    if (aGlobal === bGlobal) {
                        return a.created - b.created;
                    }
                    if (aGlobal) {
                        return -1;
                    }
                    return 1;
                });

            commit('SET_ALERTS', adminAlerts.concat(mappedAlerts));
        }
    },

    mutations: {
        SET_INFO(state, info) {
            state.info = info;
        },

        SET_ALERTS(state, alerts) {
            state.alerts = alerts;
        }
    },

    modules: {
        persistent,
        notifications,
        permissions,
        user,
        views,
        fleets
    }
};
