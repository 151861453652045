<template>
    <vehicle-details-modal ref="modal" class="bootstrap-root" @close="onClose"></vehicle-details-modal>
</template>

<script>
// Wrapper component to trick webpack into transparently loading most of the vehicle details stuff from its own chunk

import { importMoment } from '~/assets/vendor';
import { importBootstrap } from '@odm-operations-tooling/odm-frontend-cockpit-ui/src/assets/vendor';

export default {
    name: 'VehicleDetails',

    components: {
        VehicleDetailsModal: async function() {
            await Promise.all([importBootstrap(), importMoment()]);
            return import(/* webpackChunkName: "vehicleDetails" */ '~/components/comprehensive/vehicleDetails/VehicleDetailsModal');
        }
    },

    created() {
        this.$on('open', async (vehicles, fromLegacyFleetTab = false) => {
            if (!Array.isArray(vehicles)) {
                vehicles = [vehicles];
            }
            if (fromLegacyFleetTab) {
                const { default: FleetTabVehiclesAdapter } = await import(
                    /* webpackChunkName: "vehicleDetails" */ '~/business/comprehensive/vehicleDetails/fleetTabVehiclesAdapter'
                );
                vehicles = FleetTabVehiclesAdapter.adapt(vehicles);
            }
            if (this.$refs.modal) {
                this.$refs.modal.$emit('open', vehicles);
            }
        });
    },

    methods: {
        onClose() {
            this.$emit('close');
        }
    }
};
</script>
