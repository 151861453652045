import { mapGetters } from 'vuex';

import * as Authorization from '~/util/authorization';

const PAGES_WITH_FLEET_FILTER = [
    'csc_ridehailing',
    'csc_operations_fleet',
    'administration_special_zones',
    'administration_mobility_stations',
    'people_driver_supply_plan',
    'administration_no_fly_zones'
];

export default {
    computed: {
        ...mapGetters('views', ['activeId', 'isHybridFleetManagementPage']),
        ...mapGetters('fleets', ['isCurrentFleetStateless']),

        isLegacyFleetFilterActive() {
            if (this.isCurrentFleetStateless && this.isHybridFleetManagementPage) {
                return false;
            }
            if (!PAGES_WITH_FLEET_FILTER.some(pageId => Authorization.isAllowed(pageId))) {
                return false;
            }
            if (PAGES_WITH_FLEET_FILTER.includes(this.activeId)) {
                return true;
            }
            return null;
        }
    }
};
