import BuildVariables from '~/util/buildVariables';

import Vue from 'vue';
Vue.config.devtools = !BuildVariables.isProductionEnv();
Vue.config.productionTip = false;
Vue.config.performance = !BuildVariables.isProductionEnv();

Vue.prototype.$eventBus = new Vue();

import PortalVue from 'portal-vue';
Vue.use(PortalVue);

import IFrameBridge from '@odm-operations-tooling/iframe-bridge';
import { BRIDGE_EVENTS } from '@odm-operations-tooling/cockpit-commons';
import RouterProvider from '~/router';
import $store from '~/store';
import i18nPromise from '~/i18n';

import AdminAlerts from '~/util/adminAlerts';

import '@odm-operations-tooling/odm-frontend-cockpit-ui/src/assets/styles/main.scss';
import '~/assets/styles/components/mobile/index.scss';
import UserPreferences from '~/util/localStorage/userPreferences';

import App from '~/components/App';
import * as Authentication from '~/util/authentication';
import AJAX from '~/server/ajax';
import { getRawToken } from '~/util/authentication';
import Notifications from '~/util/notifications';

async function init() {
    try {
        $store.dispatch('user/getUser');
        $store.dispatch('initialisePersistentData');
        const [i18n] = await Promise.all([i18nPromise, $store.dispatch('permissions/loadPermissions')]);
        if (!i18n) {
            throw 'Error initializing the app: i18n is undefined';
        }
        $store.dispatch('permissions/initializeLivePermissions');

        // Has to be done after permissions have been loaded
        await $store.dispatch('fleets/loadFleets');

        IFrameBridge.registerContentProvider(BRIDGE_EVENTS.PERMISSIONS, () => $store.getters['permissions/permissions']);
        IFrameBridge.registerContentProvider(BRIDGE_EVENTS.ALLOWED_FLEETS, () => $store.getters['permissions/fleets']);
        IFrameBridge.registerContentProvider(BRIDGE_EVENTS.CURRENT_FLEET, UserPreferences.getCurrentFleet);

        $store.dispatch('views/initialize');

        await $store.dispatch('loadAlerts');
        AdminAlerts.showGlobalAlerts();

        // Immediately request permission to send desktop notifications
        document.addEventListener('DOMContentLoaded', function() {
            if (!Notification) {
                return;
            }
            if (Notification.permission !== 'granted') {
                Notification.requestPermission();
            }
        });

        /* eslint-disable no-new */
        new Vue({
            el: '#app',
            render(h) {
                return h(App);
            },
            router: RouterProvider.createRouter(),
            store: $store,
            i18n
        });
    } catch (error) {
        console.error(error); // eslint-disable-line

        if (error !== 'Login error') {
            document.getElementById('app').style.display = 'none';
            document.getElementById('appInitError').style.display = 'block';
        }
    }
}

Authentication.initialize()
    .then(isAuthenticated => {
        if (!isAuthenticated) {
            throw new Error('not authenticated');
        }
        const IDToken = getRawToken();
        AJAX.init(IDToken);
        init();
    })
    .catch(e => {
        Notifications.showError('An error occurred authenticating user');
        console.error(e); //eslint-disable-line
    });
