import Server from '~/server/comprehensive';
import UserPreferences from '~/util/localStorage/userPreferences';
import { Util } from '@odm-operations-tooling/cockpit-commons';

function findInitialFleet(fleets) {
    const storedFleet = UserPreferences.getCurrentFleet();
    let initialFleet = fleets.find(fleet => fleet.fleetId === storedFleet);
    if (initialFleet) {
        return initialFleet;
    }

    // If we can't restore the fleet from localStorage, use the first CS or US fleet.

    const findFirstFleetWithPrefix = prefix => fleets.find(fleet => fleet.fleetId.startsWith(prefix));
    initialFleet = findFirstFleetWithPrefix('CS');
    if (initialFleet) {
        return initialFleet;
    }
    initialFleet = findFirstFleetWithPrefix('US');
    if (initialFleet) {
        return initialFleet;
    }

    return fleets[0]; // we have no idea what a good initial fleet would be
}

/*
function isSelectedRideHailingFleetAutomatedOrMixed() {
    const vehicleMode = getSelectedRideHailingFleetVehicleMode();
    return ['AUTOMATED', 'MIXED_HUMAN_FAD'].includes(vehicleMode);
}

 */

export default {
    namespaced: true,

    state: {
        fleets: [],
        currentFleet: null
    },

    getters: {
        fleets: state => state.fleets,
        currentFleet: state => state.currentFleet,
        numberOfVehicles: (state, getters, rootState, rootGetters) => {
            const storeNamespace = rootGetters['views/isHybridFleetManagementPage'] ? 'fleetManagement' : 'tasks';
            if (!rootState.hasOwnProperty(storeNamespace) || !rootGetters[storeNamespace + '/vehicles/vehicles']) {
                return null;
            }
            return rootGetters[storeNamespace + '/vehicles/vehicles'].length;
        },
        isCurrentFleetStateless: (state, getters) => {
            return state.currentFleet && getters.isFleetStateless(state.currentFleet.fleetId);
        },
        isCurrentFleetRidehailing: (state, getters) => {
            return state.currentFleet && getters.isFleetRidehailing(state.currentFleet.fleetId);
        },
        getFleetById: state => fleetId => {
            return state.fleets.find(x => x.fleetId === fleetId);
        },
        isFleetStateless: (state, getters) => fleetId => {
            const fleet = getters.getFleetById(fleetId);
            return fleet && fleet.dataArchitecture === 'STATELESS';
        },
        isFleetRidehailing: (state, getters) => fleetId => {
            const fleet = getters.getFleetById(fleetId);
            return fleet && fleet.isRidehailing;
        },
        keepArchivedPastTripsForFleetId: (state, getters) => fleetId => {
            const fleet = getters.getFleetById(fleetId);
            return fleet && fleet.keepArchivedPastTrips;
        },
        getCurrentFleetRideHailingFleetVehicleMode: (state, getters) => {
            if (getters.isCurrentFleetRidehailing) {
                return getters.currentFleet.vehicleMode;
            }
            return null;
        }
    },

    actions: {
        async loadFleets({ commit }) {
            const fleets = await Server.getFleets();
            Util.array.sortByKey(fleets, 'fleetId');
            commit('SET_FLEETS', fleets);

            const initialFleet = findInitialFleet(fleets);
            if (initialFleet) {
                commit('SET_CURRENT_FLEET', initialFleet);
            }
        },

        setCurrentFleet({ commit }, fleet) {
            commit('SET_CURRENT_FLEET', fleet);
        }
    },

    mutations: {
        SET_FLEETS(state, fleets) {
            state.fleets = fleets;
        },

        SET_CURRENT_FLEET(state, currentFleet) {
            state.currentFleet = currentFleet;
            UserPreferences.setCurrentFleet(currentFleet.fleetId);
        }
    }
};
