import { createSocket } from '~/util/socketFactory';
import { getPermissionRealtimeUrl } from '~/server';

function createPermissionUpdatesSocket(onMessage) {
    const url = getPermissionRealtimeUrl();
    const initialMessage = '';
    return createSocket(url, initialMessage, onMessage);
}

function create(onPermissionUpdate) {
    let socket = null;

    function handlePermissionUpdate(data) {
        if (data) {
            onPermissionUpdate(data);
        }
    }

    function openSocket() {
        socket = createPermissionUpdatesSocket(handlePermissionUpdate);
        socket.openSocket();
    }

    function destroy() {
        socket.closeSocket();
        socket = null;
    }

    return {
        openSocket,
        destroy
    };
}

function createAndOpen(options) {
    const instance = create(options);
    instance.openSocket();
    return instance;
}

export default {
    create,
    createAndOpen
};
