import AJAX from '~/server/ajax';
import { API, EnvironmentService } from '@odm-operations-tooling/cockpit-commons';

const BASE = '/api/';
const FLEET_BASE = BASE + API.fleets.namespace + '/';

const AdminMessagesServiceActive = EnvironmentService.create({
    subdomain: API.adminMessages.namespace,
    defaultApiPath: {
        REST: API.adminMessages.active,
        WS: ''
    },
    localdomain: 'localhost:7000'
});

const VehicleAlertServiceByFleets = EnvironmentService.create({
    subdomain: API.vehicleAlert.namespace,
    defaultApiPath: {
        REST: API.vehicleAlert.alertSettings.byFleets,
        WS: ''
    },
    localdomain: 'localhost:7000'
});

const API_URLS = {
    alerts: AdminMessagesServiceActive.getRestUrl(),
    fleets: FLEET_BASE + API.fleets.getFleets,
    alertSettingsByFleets: VehicleAlertServiceByFleets.getRestUrl()
};

export default {
    async getAlerts() {
        return AJAX.get(API_URLS.alerts);
    },

    async getFleets() {
        return AJAX.get(API_URLS.fleets);
    },

    async getAlertSettingsByFleets() {
        return AJAX.get(API_URLS.alertSettingsByFleets);
    }
};
