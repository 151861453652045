<template>
    <splash-screen class="home">
        <h2>{{ $t('core.splash.welcome') }}</h2>
        <cockpit-title large></cockpit-title>
    </splash-screen>
</template>

<script>
import CockpitTitle from '~/components/core/CockpitTitle';
import SplashScreen from '~/components/core/splash/SplashScreen';

export default {
    name: 'Home',

    components: {
        CockpitTitle,
        SplashScreen
    }
};
</script>

<style lang="scss">
.home {
    h2 {
        font-weight: normal;
        font-size: 42px;
        margin: -25px 0 5px;
    }
}

@media (max-width: $screen-lg) {
    .home {
        h2 {
            font-size: 35px;
        }
    }
}
</style>
