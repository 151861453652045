import { Util } from '@odm-operations-tooling/cockpit-commons';
import { createAdminMessage } from '~/business/comprehensive/adminMessage/AdminMessageFactory';

function toApiRequest(adminMessage) {
    const { viewId, type, message, active, created } = adminMessage;
    return {
        viewId,
        type: type.toUpperCase(),
        message: Util.string.stripTags(message),
        active,
        created: created.toJSON()
    };
}

function fromRequest(requestMessage) {
    const { viewId, type, message, active, created } = requestMessage;
    return createAdminMessage({
        viewId,
        type: type.toLowerCase(),
        message,
        active,
        created: new Date(created)
    });
}

export { toApiRequest, fromRequest };
