import UserPreferences from '~/util/localStorage/userPreferences';

export const localStorageAdapterPlugin = store => {
    store.subscribe((mutation, state) => {
        const { type } = mutation;
        const { persistent } = state;
        const subscription = persistent.subscriptions[type];
        if (subscription && typeof subscription === 'function') {
            console.info('store subscription triggered', type);

            const updatedState = subscription(state);
            UserPreferences.setCurrentState({
                ...persistent.persistentData,
                updatedState
            });
        }
    });
};
