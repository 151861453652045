export default Object.freeze({
    requestInProgress: 'requestInProgress',
    reloadAllVisualizations: 'reloadAllVisualizations',
    openFilterModal: 'openFilterModal',
    openVehicleDetailsModal: 'openVehicleDetailsModal',

    map: Object.freeze({
        fitBoundsToLatLngPositions: 'fitBoundsToLatLngPositions',
        zoomToLatLngPosition: 'zoomToLatLngPosition'
    }),

    tasks: Object.freeze({
        scrollToVehicleInColumn: 'scrollToVehicleInColumn',
        addedCollectedVehicle: 'addedCollectedVehicle',
        openTaskEditModal: 'openTaskEditModal',
        closeTaskEditModal: 'closeTaskEditModal'
    }),

    administration: Object.freeze({
        openVehicleImageUploadModal: 'openVehicleImageUploadModal',
        closeVehicleImageUploadModal: 'closeVehicleImageUploadModal'
    }),

    fleetManagement: Object.freeze({
        changeVehicleColumnVisibility: 'changeVehicleColumnVisibility'
    })
});
