<template>
    <div
        class="navigationHamburger"
        :class="{ open: areNavLabelsShown }"
        ref="hamburger"
        title="Toggle labels"
        @click="toggleNavLabels(!areNavLabelsShown)"
    >
        <span v-for="i in [1, 2, 3, 4]" :key="i"></span>
    </div>
</template>

<script>
import UserPreferences from '~/util/localStorage/userPreferences';

export default {
    name: 'NavigationHamburger',

    data() {
        return {
            areNavLabelsShown: UserPreferences.getNavLabelsShown()
        };
    },

    mounted() {
        this.$nextTick(() => {
            this.toggleNavLabels(this.areNavLabelsShown, false);
            this.$refs.hamburger.style.display = 'block';
        });
    },

    methods: {
        toggleNavLabels(showLabels, saveState = true) {
            if (saveState) {
                this.areNavLabelsShown = showLabels;
                UserPreferences.setNavLabelsShown(showLabels);
            }

            const func = showLabels ? 'add' : 'remove';
            document.body.classList[func]('showNavBarLabels');
            window.dispatchEvent(new Event('resize'));
        }
    }
};
</script>

<style lang="scss">
.navigationHamburger {
    $bar_height: 4px;

    margin: 22px auto 37px;
    width: 6 * $bar_height;
    height: 5 * $bar_height;
    position: relative;
    cursor: pointer;
    display: none; // initially

    span {
        display: block;
        position: absolute;
        height: $bar_height;
        width: 100%;
        background: $color_white;
        left: 0;
        transform: rotate(0deg);
        transition-property: transform, width, top, left;
        transition-duration: 0.25s;
        transition-timing-function: ease-in-out;
        will-change: transform;
    }

    &:hover span {
        background: $color_ci_blue_shade5;
    }

    span:nth-child(1) {
        top: 0;
    }
    span:nth-child(2),
    span:nth-child(3) {
        top: 2 * $bar_height;
    }
    span:nth-child(4) {
        top: 4 * $bar_height;
    }

    &.open {
        span:nth-child(1),
        span:nth-child(4) {
            top: 2 * $bar_height;
            width: 0;
            left: 50%;
        }
        span:nth-child(2) {
            transform: rotate(45deg);
        }
        span:nth-child(3) {
            transform: rotate(-45deg);
        }
    }
}
</style>
