<template>
    <div class="splashScreen">
        <div class="jumbotron">
            <div class="content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SplashScreen'
};
</script>

<style lang="scss">
.splashScreen {
    .jumbotron {
        $jumbotron_height: 55vh;

        background: $color_gray_white_smoke url('~@/assets/images/drivenow_bg.png') no-repeat bottom right;
        height: $jumbotron_height;
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 84%;
        margin-left: 8%;
        margin-top: calc(#{(100vh - $jumbotron_height) / 2} - #{$csc_header_height} - 40px);
    }

    .content {
        text-align: center;
    }
}

@media (max-width: $screen-lg) {
    .splashScreen {
        .jumbotron {
            width: 100%;
            margin-left: 0;
        }
    }
}
</style>
