export const MESSAGE_TYPE_KEYS = {
    ERROR: 'error',
    WARNING: 'warning',
    SUCCESS: 'success',
    NOTIFICATION: 'notification'
};

export const DEFAULT_MESSAGE_TYPE_KEY = MESSAGE_TYPE_KEYS.WARNING;

export const MESSAGE_TYPES = {
    [MESSAGE_TYPE_KEYS.ERROR]: { name: 'Error' },
    [MESSAGE_TYPE_KEYS.WARNING]: { name: 'Warning' },
    [MESSAGE_TYPE_KEYS.SUCCESS]: { name: 'Success' },
    [MESSAGE_TYPE_KEYS.NOTIFICATION]: { name: 'Notification' }
};
