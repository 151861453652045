export const VERSION = 20; // count up when there are breaking changes to the store

export const DEFAULT_FLEET = 'CS-MUC';

export const initialState = {
    version: VERSION,
    areNavLabelsShown: false,
    collapsibleWidgets: {},
    monitoring: {
        dashboards: {},
        fleets: [DEFAULT_FLEET]
    },
    currentFleet: DEFAULT_FLEET,
    tasks: {
        collectedVins: {}
    },
    vehicleHealthRecord: {
        lanes: ['reservationBookingBusinessStateEvents', 'reservationEvents', 'engineEvents'],
        statelessLanes: ['engineEvents']
    },
    globalSettings: {
        distanceUnit: 'km'
    },
    ridehailing: {
        trips: {
            selectedTableColumns: {}
        }
    },
    fleetManagement: {
        ridehailing: {
            trips: {
                selectedTableColumns: [
                    'driver',
                    'requester',
                    'licensePlate',
                    'actions',
                    'state',
                    'pickupAddress',
                    'dropoffAddress',
                    'pickupTime'
                ],
                selectedTableFilters: {}
            },
            vehicles: {
                selectedTableColumns: [
                    'vin',
                    'licensePlate',
                    'combinedModel',
                    'businessState',
                    'businessStateChangeTimestamp',
                    'reservationReason',
                    'connectionStatus',
                    'lastConnectionStatusChange',
                    'driverId',
                    'favDutyState',
                    'favDutyReason',
                    'favDutyComment',
                    'driverShiftState',
                    'driverZoneId',
                    'tripState'
                ],
                selectedTableFilters: {}
            }
        },

        vehicles: {
            selectedTableColumns: ['licensePlate', 'vin', 'model', 'mileage', 'highVoltageBatteryStateOfCharge', 'fuelLevel', 'reachTotal'],
            selectedTableFilters: {}
        },

        persistFilterSelection: true,
        visibleMapLayersByKey: {}
    },
    reporting: {
        utilizationKpiTableColumns: null,
        fleetFilter: null
    }
};
