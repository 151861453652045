<template>
    <i class="fa fa-sign-out logoutButton" @click="logout" :title="$t('core.header.logout')" aria-hidden="true"> </i>
</template>

<script>
import * as Authentication from '~/util/authentication';

export default {
    methods: {
        logout() {
            Authentication.logout();
        }
    }
};
</script>

<style lang="scss">
.logoutButton {
    display: block;
    padding: 7px 4px 7px 5px;
    font-size: 20px;
    color: $color_ci_blue_shade1;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background: $color_ci_blue_shade1;
        color: $color_white;
    }
}
</style>
